


/*  ----------------------------------------------------------------------------
    / Test item tile
    ---------------------------------------------------------------------------- */

.test-item {
  position: relative;
  display: flex;
  .items-center;
  .h-8;
  .p-2;
  .pr-4;
  background: @color-white;
  .shadow;
  text-align: left;
  transition: opacity 150ms @ease;

  &__icon{
    .mr-1;
  }

  &__label{
    .font-semibold;
    .font-size-height(11,12);
  }

  &__label--samples {
    .ml-auto;
    .pl-2;
    .flex-none;
  }

  &__tag {
    position: relative;
    color: #fff;
    text-align: center;
    min-width: @s-4;
    padding: @s-half @s-1;
    margin: 0 @s-half 0 -@s-half;
    .font-semibold;
    .font-size-height(11,16);
    .bg-body;
    .rounded;
    &:before {
      content: "";
      .w-half;
      .-ml-half;
      height: 2px;
      .bg-body;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
    }
  }

  &__extra {
    position: absolute;
    top: 0;
    right: 0;

    > button {
      &:hover,
      &:focus {
        .bg-stone;
        outline: none;
      }
    }
  }

  &__menu-btn{
    display: block;
    .w-3;
    .h-4;
    padding: .125rem .125rem 0 .125rem;
  }

  &__menu-btn-inner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .bg-white;
    .rounded-t;
  }

  &__btn-remove{
    display: flex;
    align-items: center;
    justify-content: center;
    .w-4;
    .h-4;
  }

  &__dropdown-nav{
    position: absolute;
    right: .125rem;
  }
}

.test-item--composite {

}

.dropdown-is-open {
  .test-item {
    z-index: 2;
  }
}













/*  ----------------------------------------------------------------------------
   	/Dropdown nav
    ---------------------------------------------------------------------------- */

.dropdown-nav {
  .bg-white;
  .shadow-lg;
  animation: 200ms fadeIn 50ms both;

  &__item:not(:last-child) {
    border-bottom: 1px solid fade(@color-body, 20%);
  }


  &__button {
    .block;
    .text-base;
    .font-semibold;
    .text-left;
    .w-full;
    padding-top: .875rem;
    padding-bottom: .9375rem;
    .px-2;
    .font-size-height(11, 12);
    color: currentColor;
    transition: .15s;

    &:hover,
    &:focus {
      outline: none;
      .bg-stone;
    }
  }

  &__item:last-child .dropdown-nav__button {
    padding-bottom: 1rem;
  }
}


.dropdown-nav.vanilla {
  display: none;
}

.dropdown-nav.vanilla[data-show] {
  display: block;
}



/*  ----------------------------------------------------------------------------
   	/ Portal header
   	---------------------------------------------------------------------------- */

.portal-header{
  position: relative;
  .flex;
  .items-center;
  .pr-3;
  .pl-9;
  .py-2;
  .h-11;
  background-color: fade(@color-grey-light, 90%);


  &__back-area{
    .absolute;
    left: 1.125rem;
    top: 1.875rem;
  }

  &__headings{
    flex-grow: 2;
    .pt-2;
    .pr-3;
    .pb-2;
  }

  &__heading{
    .m-0;
  }

  &__pottles{
    flex-grow: 2;
    .pr-4;
  }

  &__cta-area{
    .py-half;
    display: flex;
    align-items: center;
    .button {
      margin-top: 0;
    }
  }
}














/*  ----------------------------------------------------------------------------
   	/ Toolbar
   	---------------------------------------------------------------------------- */

@toolbar-height-desktop: @s-11;

.app-toolbar {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: @toolbar-height-desktop;
  .pr-3;
  .pl-8;
  background: fade(@color-grey-light, 90%);

  &__back {
    .w-6;
    .h-8;
    .mr-2;
    .-ml-6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__heading {
    // .text-2xl;
    // .font-normal;
    .mb-0;
    .pb-0;
  }

  &__options {
    margin-left: auto;

    .button-group > li {
      padding: 0;
      margin-right: -1px;
    }
    .button_toolbar-item {
      &:hover,
      &:focus {
        .bg-white;
      }
    }
  }

  &__dropdown-toggle {
    + .dropdown-nav {
      position: absolute;
      top: 100%;
      right: 0;
      display: none;
    }
    &.is-active {
      + .dropdown-nav {
        display: block;
      }
    }
  }
}

.page--has-toolbar {
  main {
    margin-top: calc(@toolbar-height-desktop + @topbar-height);
  }
  .topbar {
    position: fixed;
    top: 0;
    left: @header-width-desktop;
    right: 0;
  }
  .app-toolbar {
    top: @topbar-height;
  }
  .detail-summary {
    top: calc(@toolbar-height-desktop + @topbar-height);
  }
}













/*  ----------------------------------------------------------------------------
   	/ Pottle head
   	---------------------------------------------------------------------------- */

.pottle-head{
  display: flex;

  &__icon{
    .pr-1;
    .pt-1;
  }

  &__pottle-name{
    display: flex;
    margin-top: .125rem;
  }

  &__sample-name{
    // .mb-0;
    margin-bottom: -.125rem;
    .font-semibold;
  }

  &__num{
    .w-2;
    .h-2;
    line-height: @s-1;
    .ml-1;
    .mt-1;
    .rounded-full;
    .flex;
    .items-center;
    .justify-center;
    border-width: 2px;
    .border-solid;
    .border-grey-divider;
    .text-base;
    .font-semibold;
  }

  &__barecode{
    .mb-0;
    .-mt-1;
  }
  
  &__close {
    button {
      &:hover,
      &:focus {
        .bg-stone;
        outline: none;
      }
    }
  }
}


/*  ----------------------------------------------------------------------------
/ Pottle drop zone
---------------------------------------------------------------------------- */
.pottles-drop-zone {
  .p-half;
  background: #f6f6f7;
  min-height: @s-9;
}
.pottles-drop-zone--composites {
  .pottles-collection__item {
    width: 100%;
  }
}






/*  ----------------------------------------------------------------------------
   	/ Loader
       ---------------------------------------------------------------------------- */

@keyframes loaderFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader-area{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: @header-width-desktop;
  z-index: 100;
  .flex;
  .justify-center;
  .items-center;
  animation: 0.35s loaderFadeIn;
}

.loader{
  position: absolute;
  .inset-0;
  .flex;
  .justify-center;
  .items-center;
  background-color: fade(@color-grey-light, 90%);

  &:after{
    content: '';
    display: block;
    width: @s-8;
    height: @s-8;
    border: @s-half solid tint(@color-body, 80%);
    border-right-color: @color-body;
    .rounded-full;
    // transform: translate(-50%, -50%);

    animation-duration: 1200ms;
    animation-name: loading-spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.has-loader {
  position: relative;
  .loader-area {
    position: absolute;
    left: 0;
  }
}












/*  ----------------------------------------------------------------------------
   	/Pottles item
   	---------------------------------------------------------------------------- */

.pottles-item{
  .bg-grey-10;
  .mb-1;
  .pt-1;
  .pr-3;
  .pl-3;
  .pb-3;

  &__header{
    .flex;
    .mb-3;
    padding-bottom: .6875rem;
    .border-0;
    .border-b;
    .border-solid;
    .border-grey-divider;
  }

  &__pottles-test-area{
    min-height: 7rem;
  }

  &__drop-zones{
    .flex;
    .justify-between;
  }

  &__drop-zone{
    min-height: 7.125rem;
  }

  &__drop-zone--single{
    .flex-1;
    .pr-6;
  }

  &__drop-zone--composites{
    min-width: 15rem;
    margin-right: -0.1875rem;
  }
}












/*  ----------------------------------------------------------------------------
   	/Input stepper
    ---------------------------------------------------------------------------- */

.input-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .w-14;
  .p-half;
  .rounded;
  .bg-white;


  .input-stepper__field {
    flex-basis: 1.875rem;
    max-width: 1.875rem;
    .py-0;
    .px-half;
    .bg-white;
    .border-0;
    text-align: center;
    .font-size-height(16,32);
  }
}













/*  ----------------------------------------------------------------------------
   	/Progress steps
    ---------------------------------------------------------------------------- */

.progress-steps {
  .no-bullets;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  padding: 0.125rem;
  .bg-body;
  .rounded-full;

  &__item{
    display: flex;
    margin-bottom: 0;

    &:before{
      content: '';
      position: relative;
      z-index: 1;
      display: block;
      height: 0.75rem;
      width: 1.1875rem;
      margin: 0 -0.3125rem;
      background-color: @color-grey-light;
    }

    &:first-child{
      &:before{ content: none; }
    }
  }

  &__dot{
    position: relative;
    z-index: 2;
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background-color: @color-grey-light;
    border: 3px solid @color-grey-light;
    .rounded-full;
  }

  .is-active{
    .progress-steps__dot{
      background-color: @color-body;
    }
  }

  .is-active ~ .progress-steps__item {
    &:before{
      background-color: transparent;
    }

    .progress-steps__dot {
      border-color: @color-body;
      background-color: fade(@color-white, 20%);
    }
  }
}

// .progress-step {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//
//     i {
//         position: relative;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         border-radius: 50%;
//         width: 0.75rem;
//         height: 0.75rem;
//         border: 2px solid @color-body;
//         text-indent: -5000px;
//         color: transparent;
//         overflow: hidden;
//     }
//     &.is-active i:after {
//         content: "";
//         width: 0.25rem;
//         height: 0.25rem;
//         .bg-grey;
//         border-radius: 50%;
//     }
//     &:not(:last-child):after {
//         content: "";
//         width: 0.625rem;
//         height: 0.125rem;
//         .bg-grey;
//     }
// }












/*  ----------------------------------------------------------------------------
   	/Accordion
    ---------------------------------------------------------------------------- */

.accordion{
  .border-0;
  .border-b;
  .border-solid;
  .border-grey-divider;

  &__item{
    margin-bottom: -1px;
  }

  &__header {
    position: relative;
    z-index: 10;
    margin-top: -1px;
    .border-0;
    .border-t;
    .border-solid;
    .border-grey-divider;
  }

  &__header-title {
    .h {
      .m-0;
      .p-0;
      .text-md;
    }
  }

  &__header-arrow {
    transition: transform .2s;
  }

  &__header[aria-expanded="true"] {
    .z-20;

    .accordion__header-arrow {
      transform: rotate(-180deg);
    }
  }

  &__content{
    position: relative;
    z-index: 5;
  }

  &__content-inner {
    .p-5;
  }

  .test-item__menu-btn .test-item__menu-btn-inner{
    background-color: transparent;
  }
}

.accordion__header.is-active {
  .accordion__header-extra {
    button {
      transform: scaleY(-1);
    }
  }
}

.accordion__item--solid {
  margin: 0;
  + .accordion__item--solid {
    margin: 1px 0 0;
  }
  .accordion__header {
    .bg-grey-light;
    border: 0;
    margin: 0;
  }
  .accordion__header-title {
    padding: 1.25rem 1.5rem;
    h3 {
      line-height: 0.727;
    }
  }
  .accordion__content {
    padding: 0 0.5rem 0.5rem;
    .bg-grey-light;
  }
  .accordion__content-inner {
    background: #fff;
  }
}









/*  ----------------------------------------------------------------------------
   	/portal tab
    ---------------------------------------------------------------------------- */

.portal-tab{
  .pr-3;

  &__list{
    .flex;
    margin: 0 -.5625rem;
  }

  &__btn{
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.625rem .8125rem 1.75rem;
    .font-semibold;
    .font-size-height(15,12);
    color: @color-body;
    transition: color 240ms linear;

    &:before{
      content: '';
      position: absolute;
      left: .5625rem;
      right: .5625rem;
      bottom: 0;
      display: block;
      height: @s-half;
      background-color: @color-black;
      opacity: 0;
    }

    &:hover{
      .text-black;
    }
  }

  .active{
    .portal-tab__btn{
      .text-black;

      &:before{ opacity: 1; }
    }
  }
}










/*  ----------------------------------------------------------------------------
   	/sample collection
    ---------------------------------------------------------------------------- */

.sample-collection{

}


/*  stepper block
    ---------------------------------------------------------------------------- */

.stepper-block{
  &__btn{

  }
}


/*  pottle block
    ---------------------------------------------------------------------------- */

.pottle-block {


  &__inner {
    .relative;
    .flex;
    .items-center;
    .m-0;
    .normal-case;
    .bg-stone;
    .border-0;
    .text-center;
    .rounded;

    &:before { //tickbox
      border-color: fade(@color-blue, 40%);
    }
  }

  &__icon{
    .mr-1;
    .w-5;
    min-height: @s-5;
    background: fade(@color-yellow, 30%);
    .flex;
    .items-center;
    .justify-center;
    .rounded-l;
  }

  &__label{
    .mb-0;
    .font-semibold;
    .font-size-height(15,24);
  }

  &__reference {
    width: 1.125rem;
    height: 1.125rem;
    .ml-1;
    .rounded-full;
    .text-base;
    .font-semibold;
    .flex;
    .items-center;
    .justify-center;
    border-width: 2px;
    .border-solid;
    .border-grey-divider;
  }

  &.pottle-block--toggle{
    .pottle-block__inner{
      background-color: fade(@color-blue, 10%);
      .text-blue;
    }

    .pottle-block__reference{
      border-color: fade(@color-blue, 20%);
    }
  }

  input[type="checkbox"]:not(:checked) + label {
    .pottle-block__icon,
    .pottle-block__label,
    .pottle-block__reference {
      opacity: .5;
    }
  }
}


/*  toast notification
---------------------------------------------------------------------------- */
.toast {
  position: fixed;
  right: 0;
  bottom: 0;
  left: @header-width-desktop;
  display: flex;
  align-items: center;
  transition: 0.5s @ease transform;
  transform: translateY(100%);

  .bg-blue;
  .text-white;
  .py-2;
  .pl-5;
  .pr-2;

  *,
  &:hover,
  &:focus {
    color: #fff;
  }

  &:focus-visible {
    outline: 0;
  }

  &.is-active {
    transform: none;
  }
}
.toast--success {
  .bg-green;
}
.toast--error {
  .bg-red;
}
.toast__icon {
  .mr-1;
}
.toast__close {
  margin-left: auto;
  color: inherit;
}


/*  tooltip
---------------------------------------------------------------------------- */
.tooltip {
  .bg-white;
  .text-body;
  .text-base;
  .font-semibold;
  .p-1;
  .shadow;
  .absolute;
  min-width: 11.25rem;
  text-align: center;
  margin-top: -2rem;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transition: .15s;
}


/*  custom select dropdown
---------------------------------------------------------------------------- */
.custom-select {
  position: relative;
  min-width: 10rem;

  &__button {
      .flex;
      .items-center;
      .relative;
      .font-semibold;
      .text-md;
      .leading-lg;
      .border;
      .border-solid;
      .border-grey-divider;
      .rounded;
      .py-1;
      .pl-1;
      .pr-6;
      background: 0;
      transition: .1s;

      &:hover,
      &:focus {
          .border-grey-40;
      }

      svg {
          .mr-1;
      }

      &:before {
          content: "";
          .bg-grey-divider;
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          right: @s-4;
      }

      &:after {
          right: 0.6rem;
          opacity: 1;
          width: 0.75rem;
          height: 0.75rem;
          margin-top: -0.375rem;
      }

      &[disabled] {
          color: inherit;
          background: inherit;
          opacity: 0.2;
          .border-grey;
          &:before {
              .bg-grey;
          }
      }
  }
}


/* ----------------------------------------------------------------------------
  payment modal
---------------------------------------------------------------------------- */
.stripe-payment-form {
  .StripeElement {
    background: @color-grey-light;
    border: 1px solid transparent;
    padding: 1rem;
    .rounded;
  }
  .StripeElement--focus {
    border-color: @color-yellow;
  }
}
.stripe-payment-form__heading {
  .font-semibold;
}

/* ----------------------------------------------------------------------------
 Topbar
---------------------------------------------------------------------------- */
@topbar-height: 2.5rem;

.topbar {
  position: relative;
  z-index: 100;
  height: @topbar-height;
  background: tint(@color-grey, 70%);
  color: @color-grey;
  .user {
    margin-left: auto;
  }
}


/*----------------------------------------------------------------------------
/ User bar
---------------------------------------------------------------------------- */
.user {
  position: relative;
  cursor: pointer;
  .dropdown-nav {
    opacity: 0;
    animation: none;
    pointer-events: none;
  }
  a,
  a:hover,
  a:focus {
    color: currentColor;
  }
}
.user__summary {
  .text-md;
  .flex;
  .items-center;
  color: currentColor;
}
.user__summary-link {
  .flex;
  .items-center;
  color: currentColor;

  strong {
    color: currentColor;

  }
  &.is-active {
    .user__arrow {
      background: #fff;
      color: @color-black;
      transition: 200ms @ease;
      > svg {
        transform: scaleY(-1);
      }
    }
    + .dropdown-nav {
      top: 100%;
      opacity: 1;
      pointer-events: auto;
      animation: fadeIn 200ms @ease 0ms both;
    }
  }
}
.user__icon {
  .mr-1;
}
.user__half {
  .mr-half;
}
.user__arrow {
  width: 1.5rem;
  height: 2rem;
  margin-left: 0.375rem;
  .flex;
  .items-center;
  .justify-center;
  .rounded-t;
}

/*----------------------------------------------------------------------------
/ Modal
---------------------------------------------------------------------------- */
.modal {
  .fixed;
  .z-50;
  .inset-0;
  .flex;
  .items-center;
  .justify-center;
}
.modal__overlay {
  .fixed;
  .inset-0;
  .bg-black;
  .opacity-50;
}
.modal__content {
  .relative;
  .bg-white;
  .shadow;
  .w-full;
  .max-w-700;
  .max-h-90;
  .p-5;
  .overflow-auto;
  @media (min-width: @s-break) {
    .p-10;
  }
}
.modal__close {
  position: absolute;
  top: 0;
  right: 0;
}
.modal__close-button {
  .w-10;
  .h-10;
  .flex;
  .items-center;
  .justify-center;
}


/*----------------------------------------------------------------------------
/ Modal: Order Testing Supplies
---------------------------------------------------------------------------- */
.modal--solo.supplies-modal {
  .modal__content {
    max-height: 90vh;
  }
}
.order-supplies {
  .-m-10;
}
.order-supplies__header {
  .pt-6;
  .pb-5;
  .px-9;
}
.order-supplies__heading {
  .mb-0;
  .pb-0;
}
.order-supplies__step {
  .text-base;
  .uppercase;
  .font-semibold;
}
.order-supplies__body {
  .bg-grey-10;
  .mx-1;
  .pt-9;
  .pb-2;
  .px-9;
}
.order-supplies__footer {
  .bg-grey-10;
  .pb-5;
  .px-9;
  .mx-1;
  .mb-1;
  .flex;
  .items-center;
  .justify-between;

  .button {
    .mt-0;
  }
}
.order-supplies__back {
  svg {
    .m-0;
  }
}
.order-supplies__checkout {
  .ml-auto;
  svg {
    .-my-1;
    .-mr-half;
    .ml-half;
  }
}
.order-supplies__footer-text {
  .pr-4;
  .text-base;
  line-height: 1.4;
}
.order-supplies__order {
  .ml-auto;
  svg {
    .mr-half;
  }
}

.order-supplies__items {
  .flex;
  .flex-wrap;
  .order-supply {
    .mr-px;
    .mb-px;
    width: 32.9%;
  }
}

.order-supply {
  .bg-white;
  .rounded;
  .p-2;
  .flex;
  .flex-col;
}
.order-supply__image {
  .w-16;
  .h-16;
  .m-1;
  .mb-2;
  img {
    .mx-auto;
  }
}
.order-supply__text {
  line-height: 1.1;
}
.order-supply__label {
  .font-semibold;
  .mb-half;

  .qty {
    .font-semibold;
    .mb-half;

    .qty {
      .font-semibold;
    }

    &--with-qty {
      .font-normal;
    }

    label .required {
      display: none;
    }
  }
}
  .order-supply__desc {
    .font-semibold;
    .text-base;
  }

  .order-supply__stepper {

    .mt-auto;
    .pt-2;

    .input-stepper {
      .bg-grey-10;
    }

    .input-stepper__field {
      background: 0;
    }

    .button_stepper {
      .bg-white;
      .text-body;
    }
  }

  .order-supplies__copy {
    .mt-4;

    strong {
      color: currentColor;
    }
  }

  .order-supplies__details {

    .mt-4;
    .flex;

    .col {
      .flex-1;
    }

    h4 {
      .font-semibold;
      .mb-1;
    }

  }

  .order-supplies__redirect {
    .mt-4;
    .pt-3;
    border-top: 1px solid fade(@color-body, 20%);
  }

  .order-supplies__redirect-form {
    .fields-list {
      .flex;
      .flex-wrap;
      .mb-2;
    }

    .field {
      .w-full;
    }

    .field-checkbox {
      .mt-0;
    }

    .field.postcode {
      width: 35%;
      .pr-2;
    }

    .field.city {
      width: 65%;
    }
  }


  /*----------------------------------------------------------------------------
  / Switch toggle checkbox
  ---------------------------------------------------------------------------- */

  .switch-ui {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    margin: 0;
    cursor: pointer;
  }

  .switch {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    width: 30px;
    height: 19px;
    border-radius: 1rem;
    border: 2px solid fade(@color-body, 30%);
    cursor: pointer;
    outline: 0;
    transition: .2s ease-in-out;

    + .switch-ui__label-a {
      margin-left: 0.5rem;
    }

    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 11px;
      height: 11px;
      background: currentColor;
      border-radius: 50%;
      transition: .2s ease-in-out;
    }

    &[type="checkbox"],
    &[type="radio"] {
      &:hover,
      &:focus {
        outline: 0;
        border-color: @color-body;
      }

      &:checked {
        border-color: @color-blue;

        &:after {
          left: calc(100% - 13px);
          background: @color-blue;
        }

        + .switch-ui__label-a {
          color: @color-blue;
        }
      }
    }
  }

  .switch-ui--two-labels {
    .switch-ui__label-a {
      order: 1;
      margin: 0;
      color: @color-blue; //default choice
    }

    .switch-ui__label-b {
      order: 3;
    }

    .switch {
      order: 2;
      margin: 0 0.5rem;

      &[type="checkbox"],
      &[type="radio"] {
        &:checked {
          ~ .switch-ui__label-a {
            color: currentColor;
          }

          ~ .switch-ui__label-b {
            color: @color-blue;
          }
        }
      }
    }
  }

  /*----------------------------------------------------------------------------
  / Modal: Tour
  ---------------------------------------------------------------------------- */
  @tour-width: 40.5rem;
  @tour-height: 32.5rem;

  .modal--solo.tour-modal {
    .modal__content {
      width: @tour-width;
      height: @tour-height;
      padding: 0;
      overflow: hidden;
    }

    .modal__content-inner {
      width: @tour-width;
      height: @tour-height;
      position: absolute;
      .inset-0;
      overflow: hidden;
    }

    strong {
      color: currentColor;
    }
  }

  .tour-splash {
    width: 100%;
    height: @tour-height;
    padding: 6rem 3rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: url('/images/tour-splash-bg.svg') no-repeat 100% 90%;
  }

  .tour-splash__heading {
    width: 27.75rem;
    margin: 0 0 1.5rem;
  }

  .tour-splash__subheading {
    width: 25.5rem;
  }


  .tour {
    width: 100%;
  }

  .tour__page {
    position: relative;
    height: @tour-height;
    padding: 0 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .tour__header {
    padding: 2.25rem 2.5rem 2rem;
  }

  .tour__body {
    .bg-grey-light;
    .text-md;
    padding: 4.5rem 0 0 2.5rem;
    flex: 1;
    display: flex;
  }

  .tour__blurb {
    width: 17.5rem;
    flex-shrink: 0;
  }

  .tour__media {
    flex: 1;
    margin: -1.875rem 0 0;
    text-align: right;

    img {
      width: 17.5rem;
      display: inline;
    }
  }

  .tour__buttons {
    position: absolute;
    left: 3rem;
    bottom: 5rem;
    margin: 0;

    > li {
      padding: 0;
    }

    > li + li {
      margin-left: 0.5rem;
    }
  }

  .tour__dots {
    .no-bullets;
    margin: 0.25rem 0 0;
    display: flex;
  }

  .tour__dot {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0;
    border-radius: 50%;
    background: fade(@color-body, 25%);

    & + & {
      margin-left: 0.5rem;
    }

    &.is-active {
      .bg-body;
    }
  }

  .tour__dont-show {
    position: absolute;
    bottom: 2.8125rem;
    left: 3rem;
    background: 0;

    label {
      padding: 0 0 0 1.5rem;
      border: 0;
      .font-normal;
    }

    label:before {
      right: auto;
      left: 0;
    }

    input[type="checkbox"]:checked + label {
      background: 0;
    }
  }

