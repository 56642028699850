
/*  ----------------------------------------------------------------------------
   	/Header
    ---------------------------------------------------------------------------- */
@header-width-desktop: @s-11;

.app-header {
    width: @header-width-desktop;

    + main {
        margin-left: @header-width-desktop;
    }
    + main .app-toolbar {
        left: @header-width-desktop;
    }

    a:focus {
        outline: none;
    }

    &__button {
        width: 100%;
        // height: @header-width-desktop;
        height: @s-10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .h {
          text-transform: none;
          text-align: center;
          margin: 0.325em 0 0;
          padding: 0;
        }
    }

    &__icon {
        position: relative;
    }
    &__notification {
        .bg-red;
        color: #fff;
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
        font-size: 0.5625rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
    }

    &__logo {
        height: @header-width-desktop;
        flex-shrink: 0;
        .bg-yellow;
        .text-black;
        // border-bottom: 1px solid fade(white, 20%);
        // margin-bottom: @s-2;
    }
}



.app-nav {
    a {
        color: #fff;
    }

    .app-header__button {
        position: relative;
        margin: 0;
        transition: background-color 180ms;

        svg,
        .h {
            opacity: 0.5;
            transition: opacity 180ms;
        }

        .ext {
            display: inline-block;
            margin-left: 0.25rem;
            svg {
                opacity: 1;
            }
        }

        &:after{
            position: absolute;
            top: 50%;
            right: 0;
            width: 0;
            height: 0;
            margin-top: -@s-1;
            border-top: @s-1 solid transparent;
            border-bottom: @s-1 solid transparent;
            border-right: @s-1 solid @color-grey-light;
        }

        &:hover,
        &:focus {
            background-color: fade(white, 10%);
            svg,
            .h {
                opacity: 1;
            }
        }

        &.active{
            svg,
            .h {
                opacity: 1;
            }

            &:after{
                content: '';
            }
        }
    }

    .app-nav__item--new-order {
        .mt-6;

        .app-header__button {
            * {
                opacity: 1;
                .text-yellow;
            }
        }
    }

    .app-nav__item--order-supplies {
        .app-header__button {
            * {
                opacity: 1;
                .text-orange;
            }
        }
        .h {
            width: 3.75rem;
        }
    }

    &__item--logout .app-header__button {
        .h-5;
        .mt-px;
        background-color: fade(white, 10%);
        &:hover,
        &:focus {
            background-color: fade(white, 20%);
        }
    }

    &--middle{
        flex-grow: 2;
        padding-top: @s-9;
    }

    &--bottom {
        .mb-8;
    }
}












/*  ----------------------------------------------------------------------------
   	/Order - apply
   	---------------------------------------------------------------------------- */

.order-apply {
    // height: calc(100vh - @toolbar-height-desktop);
    // overflow: hidden;

    &__header{
        .py-2;
        .border-0;
        .border-t-3;
        .border-solid;
        .border-grey-divider;
    }

    &__heading{
        flex-grow: 1;
        .mb-0;
    }

    &__inner {
        position: relative;
        &:hover {
            z-index: 2;
        }
    }

    &__btn-add{
        margin-right: -2px;
        .p-1;
        .bg-body;
        .rounded-full;
        .text-white;
    }

    &__pottles{
        position: relative;
        z-index: 1;
    }

    &__pottles-inner{
        padding: 0 @s-4 @s-24 @s-8;
        @media (min-width: @l-break) {
            padding-right: @s-5;
        }
    }

    &__pottles-item{

    }

    &__test{
        position: fixed;
        z-index: 2;
        top: calc(@toolbar-height-desktop + @topbar-height);
        right: 0;
        bottom: 0;
        overflow: auto;
        overflow-x: visible;
        overflow-y: auto;
        .webkit-scrolling;
    }

    &__test-area{
        display: flex;
        flex-wrap: wrap;
        margin: -.1875rem;
    }

    &__test-item{
        display: block;
        position: relative;
        padding: 0 0 1px 1px;
        width: 100%;
        @media (min-width: @m-break) {
            width: 50%;
        }
        @media (min-width: @xl-break) {
            width: 33.333333%;
        }
        @media (min-width: @xxl-break) {
            width: 25%;
        }
    }

    // /portal/order/7586/tests/honey
    // &__pottles-inner{
    //     padding: @s-5;
    // }
}













/*  ----------------------------------------------------------------------------
    /Pottles UI
    ---------------------------------------------------------------------------- */


.pottles-list {

    &--locked {
        .pottles-header {
            border: 0;
            padding-bottom: 0;
        }
        .test-item {
            .bg-stone;
            .shadow-none;
        }
    }
}


.pottles-collection {
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: block;
        position: relative;
        padding: 0 1px 1px 0;
        width: 100%;
        &:hover {
            z-index: 2;
        }
        @media (min-width: @l-break) {
            width: 50%;
        }

        .accordion & {
            width: 100%;
            @media (min-width: @xxl-break) {
                width: 50%;
            }
        }
    }

    &__error {
        border: 1px dashed fade(@color-red, 30%);
        .validation-errors {
            align-items: center;
            justify-content: center;
        }
        .validation-errors__list {
            padding: 0;
        }
        .validation-errors__item {
            align-items: center;
            &:before {
                content: "";
                width: 17px;
                height: 15px;
                background: url('/images/icons/validation-error.svg@{timestamp}') no-repeat;
                background-size: contain;
                margin: 0 5px 0 0;
            }
            .txt {
                padding-top: 0.15rem;
            }
        }
    }
}













/*  ----------------------------------------------------------------------------
    /Order - Summary
    ---------------------------------------------------------------------------- */

.order-summary {

    &__pottles-inner{
        padding: @s-5;
    }

    .pottles-item{
        .bg-white;

        &__header{
            .mb-2;
            .pb-0;
            border-bottom: 0;
        }
    }
}


.contact-block {
    .py-3;

    + .contact-block {
        .border-t;
        .border-grey-divider;
    }

    &__edit {
        .w-4;
        .h-4;
        .flex;
        .items-center;
        .justify-center;
    }
}







/*  ----------------------------------------------------------------------------
   	/Content block
   	---------------------------------------------------------------------------- */

.content-heading{
    .pt-12;
    .pb-10;
}

.content-block{
    .pt-10;
    .pb-10;
}




















/*  ----------------------------------------------------------------------------
   	/Modal
    ---------------------------------------------------------------------------- */
@keyframes modalEntry {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal {
    animation: modalEntry .15s;
}

.modal--solo {
    position: fixed;
    .inset-0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background: fade(black, 75%);
    padding: 1rem;
    @media (min-width: @s-break) {
        padding: 2rem;
    }
    .modal__content {
        max-height: 90vh;
        overflow-y: auto;
        @media (min-width: @s-break) {
            max-height: 80vh;
        }
    }
}









/*  ----------------------------------------------------------------------------
   	/generic content
    ---------------------------------------------------------------------------- */

.portal-content{
    padding: @s-5 @s-3 @s-32 @s-9;
}










/*  ----------------------------------------------------------------------------
   	/portal topbar
    ---------------------------------------------------------------------------- */

.portal-topbar{
    .flex;
    .items-center;
    .pr-3;
    .pl-9;
    background: #dfdfe0;
    position: sticky;
    top: 0;
    z-index: 3;
    &.is-active {
        background: #fff;
    }
}















.filter-tag{
    display: flex;
    padding: @s-half @s-half @s-half @s-3;
    background: @color-white;
    .rounded;

    &__text{
        padding-top: .125rem;
        padding-bottom: .0625rem;
        margin-bottom: 0;
    }
}











/*  ----------------------------------------------------------------------------
   	/order filters
    ---------------------------------------------------------------------------- */

.order-filters{
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    .py-2;
    .pl-3;

    &__list{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin: -@s-half;
    }

    &__item{
        position: relative;
        padding: @s-half;
    }

    &__input[type="text"]{
        min-width: 14.625rem;
        padding: .8125rem 2.625rem .9375rem 1.0625rem;
        .font-semibold;
        .font-size-height(11,12);
    }

    &__btn-search{
        position: absolute;
        display: block;
        top: @s-half;
        right: @s-half;
        padding: @s-2;
    }
}




/*  ----------------------------------------------------------------------------
    / Edit Group
    ---------------------------------------------------------------------------- */

.available-tests {
    max-height: 50vh;
}









/*  ----------------------------------------------------------------------------
    / Select all
    ---------------------------------------------------------------------------- */
.select-all {
    position: relative;

    &__button {
        position: relative;
        .flex;
        .justify-center;
        .items-center;
        .w-2;
        .h-2;
        padding: 0;
        border: 2px solid fade(@color-body, 30%);
        transition: .15s;
        .rounded;
        cursor: pointer;

        svg { opacity: 0; }

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            width: 0.625rem;
            height: .125rem;
            margin-top: -.0625rem;
            background: #fff;
            opacity: 0;
        }

        .all-selected & {
            .bg-blue;
            .border-blue;
            .text-white;

            svg {
                opacity: 1;
            }
        }

        .some-selected & {
            .bg-body;
            .border-body;
            .text-white;

            &:before {
                opacity: 1;
            }
        }

        &.select-all__label--part{
            // border: 2px solid @color-body;
            .bg-body;
            &:before { opacity: 1; }
        }

        &[disabled] {
            background: 0;
            border: 2px solid fade(@color-body, 30%);
        }
    }

}
