.blends-content {
	padding: @s-5 @s-3 @s-32 @s-9;
}

.inventory-global-actions {

}

.inventory-toolbar {
  display: flex;
  align-items: center;
  margin: -1rem 0 1rem;
  min-height: 2.625rem;
  .select-all {
    margin: 0 0.5rem 0 1rem;
  }
}
.inventory-actions {
	.button svg {
		margin-left: -0.5rem;
	}
}
.inventory-filtering {
  .result-filter__list {
    padding-right: 0.375rem;
  }
  .button__open-filters {
    margin-right: 0.5rem;
  }
}

/*-----------------------------------------------------------------------------------------
	/Inventory - empty state
-----------------------------------------------------------------------------------------*/
.sync-blends-inventory {
	width: 100%;
	padding: @s-8;
	border: 1px dashed fade(@color-grey, 40%);
	.font-semibold;

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	svg {
		margin-right: 0.5rem;
		animation: spin 1s infinite linear paused;
	}
	&:hover,
	&:focus {
		color: @color-black;
		svg {
			animation-play-state: running;
		}
	}
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.download-csv-template {
	.font-semibold;
	color: currentColor;
	display: inline-flex;
	align-items: center;
	svg {
		margin-right: 0.5rem;
	}
}


/*-----------------------------------------------------------------------------------------
	/Inventory
-----------------------------------------------------------------------------------------*/
#page--blending-list,
.page--blends-inventory,
.page--blending-experiments {
  main {
    margin-top: 0;
  }
  .topbar {
    position: relative;
    left: 0;
  }
  .portal-tab,
  .portal-tab__btn {
    height: 5.5rem;
  }
}
.inventory {

}
.inventory-grid {
	display: grid;
	grid-template-columns:
		[icon] 3rem
		[heading] 1fr
		[cost] 6.5%
		[weight] 6.5%
		[unit] 1fr
		[date-3in1] 9.3%
		[date-mpi5] 6.5%
		[date-c4] 6.5%
		[date-tutin] 6.5%
		[button] 3rem;
}
.inventory-header {
	background: #fff;
	padding: 22px 0 25px;
	margin: 0 0 1px;
	> div {
		.text-base;
		.font-semibold;
		line-height: 1.1;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.inventory-header__icon,
	.inventory-header__status {
		padding-left: 0;
	}
	.inventory-header__unit {
		padding-left: calc(1rem + 3ch + 0.375rem);
	}
	&__icon,
	&__status {
		opacity: 0.5;
	}
	&__icon {
		text-align: right;
		padding-right: 0.875rem;
		&:before {
			content: "";
			display: inline-block;
			width: 0.75rem;
			height: 0.75rem;
			background: fade(@color-grey, 50%);
			border-radius: 50%;
		}
	}
	&__date {
		text-align: right;
	}
	.sublabel {
		opacity: 0.5;
	}
}


/*-----------------------------------------------------------------------------------------
	/Inventory item
-----------------------------------------------------------------------------------------*/
.inventory-item {
	background: #fff;
	margin: 0 0 1px;
	&.disabled {
		opacity: 0.5;
	}
}
.inventory-item__header {
	> div {
		min-height: 3.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 1rem;
		line-height: 1.1;
	}
	.inventory-item__icon {
		padding: 0 0.5rem 0 0;
	}
	.inventory-item__heading {
		padding-left: 0;
	}
	.inventory-item__btn-area {
		padding: 0;
	}
}
.inventory-item__icon {
	width: 3rem;
	height: 3.5rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	svg {
		margin-top: -0.25rem;
	}
}
.inventory-item__heading {
	.text-md;
	.font-semibold;
	padding-top: 1.125rem;
}
.inventory-item__cost,
.inventory-item__weight,
.inventory-item__total,
.inventory-item__unit,
.inventory-item__date {
	.text-base;
	.font-semibold;
}
.inventory-item__cost,
.inventory-item__weight,
.inventory-item__total {
	padding-top: 1.375rem;
	color: @color-blue;
	text-align: right;
}
.inventory-item__weight {
	div + div {
		margin-top: 0.25rem;
	}
}
.inventory-item__total {
	margin-right: 0.5rem;
	.profit {
		.text-base;
		.font-semibold;
		line-height: 1.5;
	}
}
.inventory-item__unit {
	padding-top: 1.375rem;
	color: @color-blue;
}
.inventory-item__unit-row {
	display: flex;
	& + & {
		margin-top: 0.25rem;
	}
}
.inventory-item__unit-qty {
	text-align: right;
	min-width: 3ch;
	white-space: nowrap;
}
.inventory-item__unit-blocks {
	margin-left: 0.5rem;
	display: flex;
	flex-wrap: wrap;
}
.inventory-item__unit-block {
	width: 0.5rem;
	height: 0.75rem;
	margin: 0 0 2px 2px;
	background: fade(@color-blue, 15%);
	border: 1px solid fade(@color-blue, 50%);
}

.inventory-item__date {
	padding-top: 1.375rem;
	text-align: right;
}
.inventory-item__toggle {
	.w-6;
  .h-7;
	display: flex;
	align-items: center;
	justify-content: center;

  & .icon__arrow-down {
    opacity: .4;
    transition: opacity 240ms linear;
  }

  &[aria-expanded="false"]:hover .icon__arrow-down {
    opacity: 1;
  }

  &[aria-expanded="true"] .icon__arrow-down {
    opacity: 1;
    transform: rotate(180deg);
  }
}
.inventory-item__drawer {
	padding-bottom: 2.5rem;
}


/*-----------------------------------------------------------------------------------------
	/Inventory sub item
-----------------------------------------------------------------------------------------*/
.inventory-subitem {
	margin: 0 0 1px;
	> div {
		min-height: 3.5rem;
		background: @color-stone;
		color: @color-khaki;
		padding-left: 1rem;
		padding-right: 1rem;
		line-height: 1.1;
	}
	> .empty {
		background: 0;
	}
}
.inventory-subitem__heading {
	.text-md;
	display: flex;
	line-height: 1.2;
	padding-top: 1.25rem;
	svg {
		margin-top: -0.25rem;
		margin-right: 0.75rem;
		margin-left: 0.5rem;
	}
}
.inventory-subitem__date {
	.text-base;
	.font-semibold;
	padding-top: 1.375rem;
	text-align: right;
	&.fade {
		color: fade(@color-khaki, 50%);
	}
}



/*-----------------------------------------------------------------------------------------
	/Inventory - Financial analysis
-----------------------------------------------------------------------------------------*/
.inventory--financial-analysis {
	.inventory-grid {
		grid-template-columns:
		[icon] 3rem
		[heading] 0.5fr
		[cost] 15%
		[weight] 15%
		[unit] 1fr
		[total] 0.5fr;
	}
	.inventory-item__cost,
	.inventory-item__weight,
	.inventory-item__total {
		.text-md;
		.font-normal;
		padding-top: 1.25rem;
	}
	.inventory-item__unit-qty {
		text-align: left;
	}
}

.inventory-item--subtotal {
	min-height: 4.5rem;

	.inventory-item__icon {
		padding-top: 0.75rem;
		padding-right: 1rem;
	}
	.inventory-item__heading {
		.font-normal;
		padding-top: 1.75rem;
	}
	.inventory-item__cost,
	.inventory-item__weight,
	.inventory-item__total {
		.font-semibold;
		padding-top: 1.75rem;
	}
	.inventory-item__unit {
		padding-top: 2rem;
	}
}

.inventory-item--results {
	padding: 0.5rem 1rem;
	margin-top: -5px;
	.inventory-item__inner {
		background: @color-stone-light;
		margin: 0 -0.5rem;
		box-shadow: inset 0 0 0 0.25rem @color-yellow-light;
	}
	.inventory-item__header {
		margin: 0 -0.5rem;
		> * {
			color: @color-khaki;
			padding-top: 1rem;
			padding-bottom: 1rem;
			display: flex;
			align-items: center;
		}
	}
	.inventory-item__icon {
		width: 60px;
		height: 100%;
		padding: 0;
		margin: 0 0 0 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: @color-yellow-light;
		svg {
			width: 2rem;
			height: 2rem;
			margin: 0;
		}
	}
	.inventory-item__heading {
		padding-left: 2.5rem;
	}
	.inventory-item__cost,
	.inventory-item__weight {
		justify-content: flex-end;
	}
	.inventory-item__cost-input {
		display: flex;
		align-items: center;
		input {
			.text-md;
			text-align: right;
			height: 2.5rem;
			width: 5.5rem;
			background: #fff;
			border-color: transparent;
			margin: 0 0.5rem 0 0;
			&:focus {
				border-color: @color-yellow;
			}
		}
	}
	.inventory-item__total {
		.font-semibold;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
	}
}


/*-----------------------------------------------------------------------------------------
	/Blending experiments
-----------------------------------------------------------------------------------------*/
.blending-experiment-grid {
	display: grid;
	grid-template-columns: 3rem 0.4fr 0.25fr 0.333fr;
}
.blending-experiment {
	background: #fff;
	margin: 0 0 1px;
}
.blending-experiment__header {
	cursor: pointer;
	> div {
		min-height: 3.5rem;
    height: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
		line-height: 1.1;
	}
	.blending-experiment__icon {
		padding: 1.125rem 0.5rem 1.125rem 0;
	}
	.blending-experiment__heading {
		padding-left: 0;
    padding-bottom: 1.25rem;
    line-height: 1.3;
	}
	.blending-experiment__status {
		padding-right: 0;
    padding-bottom: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
	}
}
.blending-experiment__icon {
	width: 3rem;
	height: 3.5rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.blending-experiment__heading {
	.text-md;
	.font-semibold;
	padding-top: 1.25rem;
  line-height: 1.3;
}
.blending-experiment__date {
	.text-base;
	.font-semibold;
	padding-top: 1.375rem;
	text-align: left;
}
.blending-experiment__status {
	padding-top: 1.125rem;
}

/*-----------------------------------------------------------------------------------------
	/Blend flex table
-----------------------------------------------------------------------------------------*/
.blend-flex-table {
	.text-md;

	.col-3in1,
	.col-mpi5 {
		position: relative;

		&:after {
			content: "";
			z-index: 2;
			width: 0.5rem;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(270deg, @color-grey 0%, fade(@color-grey, 0%) 100%);
			opacity: 0.08;
		}
	}
}

.blend-flex-table-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
}

// Header
.blend-flex-table-header {
	background: #fff;
	border-bottom: 1px solid @color-grey-light;
}
.blend-flex-table-header__options {
	grid-column: span 3;
	padding: 1rem;
}
.blend-flex-table-header__select-all {
	display: flex;
	align-items: center;
	.select-all {
		margin-right: 0.5rem;
		.leading-base;
	}
}
.blend-flex-table-header__preview {
	.text-base;
	.font-semibold;
	padding: 1rem;
}
.blend-flex-table-header__col {
	padding: 1rem 1rem 1.625rem;
	&:last-child {
		margin-right: 1rem;
		.blend-flex-table-header__label {
			margin-right: -1rem;
		}
	}
}
.blend-flex-table-header__label {
	.text-base;
	.font-semibold;
	.rounded;
	text-transform: uppercase;
	background: fade(@color-orange, 15%);
	color: @color-orange;
	padding: 0 0.5rem;
	margin: 0 -0.5rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: color .15s, background-color .15s;
	&:hover,
	&:focus {
		background: @color-orange;
		color: #fff;
	}
	.icon {
		width: 1rem;
		height: 1rem;
    margin: 0 -0.25rem 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: rotate(-90deg);
    order: 2;
		svg {
			width: 0.75rem;
			height: 0.75rem;
		}
    + span {
      padding-right: 0.5rem;
    }
	}
	+ .blend-flex-table-header__units {
		margin-top: 0.875rem;
	}
}
.blend-flex-table-header__units {
	margin-top: 0.875rem;
	display: none;

	> div {
		flex: 1;
	}

	.text-base;
	.font-semibold;

	.col {
		line-height: 1.3;
    position: relative;
		&.sortable {
			&:after {
				content: "";
				width: 6px;
				height: 8px;
				background: url('/images/icons/asc-desc-default.svg') no-repeat 50%;
				position: absolute;
				top: 3px;
				left: -12px;
				opacity: 0.5;
				transition: opacity .15s;
			}
			&:hover {
				&:after {
					opacity: 1;
				}
			}
		}
    &.sorted {
      &.asc,
      &.desc {
        &:after {
          background-image: url('/images/icons/asc-desc.svg');
					opacity: 1;
        }
      }
      &.asc {
        &:after {
          transform: scaleY(-1);
        }
      }
    }
		&:not(.sortable) {
			button {
				cursor: default;
			}
		}
	}
	.col-label {
		padding: 0;
	}
	.col-unit {
		opacity: 0.5;
	}
  .col button {
    width: 100%;
    text-align: left;
  }

}


// Targets row
.blend-flex-table-targets {
	background: #fff;
	border-bottom: 1px solid @color-grey-light;

	.blend-flex-table__heading {
		color: @color-green;
    padding: 1rem 1rem 1rem 3rem;

    .text{
      width: 100%;
    }
		.title {
			.text-md;
			display: flex;
      align-items: center;
      .pr-2;
		}
		.title svg {
			margin-left: 0.5rem;
    }
    .subheading {
      .text-base;
      .ml-auto;
    }
	}
}
.blend-flex-table-targets__preview {
	.text-base;
	.font-semibold;
	padding: 0.333rem 0 0;
	color: @color-green;
	display: flex;
	align-items: baseline;
	line-height: 1.2;
	.num {
		margin: 0 0.25rem 0 0;
		padding: 0 0.125rem;
		background: fade(@color-green, 15%);
		.rounded;
	}
}
.blend-flex-table-targets__units {
	display: none;

	> div {
		flex: 1;
	}

	.col-icon {
		color: @color-green;
	}
	.col-label {
		.rounded;
		display: inline-block;
		line-height: 0.8;
		padding: 0.375rem 0.5rem;
		margin: 0 0 0 -0.5rem;
		opacity: 0.5;
	}
	.col-unit {
		.text-base;
		.font-semibold;
		line-height: 1;
		opacity: 0.5;
		&:not(:empty) {
			margin-top: 0.375rem;
		}
	}
	.col-label.is-highlighted {
		background: @color-green;
		color: #fff;
		margin: 0;
		opacity: 1;
		+ .col-unit {
			color: @color-green;
			opacity: 1;
		}
	}

}


// Rows
.blend-flex-table__row {
	background: #fff;
	& + & {
		border-top: 1px solid @color-grey-light;
	}
}
.blend-flex-table__heading {
	grid-column: span 3;
  display: flex;
  align-items: flex-start;
	height: 100%;
	padding: 1rem;
	.icon {
		width: 2rem;
		height: 2rem;
		margin: 0 0.25rem 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
    flex-shrink: 0;
		svg {
			width: 2rem;
			height: 2rem;
		}
	}
	.title {
		.text-xl;
		.font-semibold;
    line-height: 1.3;
	}
	.date {
		.text-base;
		.font-semibold;
		line-height: 1;
		margin: 0.25rem 0 0;
		display: block;
		+ .unit {
			margin-top: 1em;
		}
	}
	.unit {
		.text-base;
		.font-semibold;
		line-height: 1.1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 11.25rem;
  }
  	.unit__input {
		  width: 5em;
		  font-size: 1em;
		  padding: 0.25rem 0.5rem;
		  margin: 0 0 0 1.5rem;
		  flex-shrink: 0;
	  }
  .pottle-qty {
    margin: 0 0 0 auto;
    padding: 0 0 0 1rem;
    display: flex;
	align-items: center;
	flex-shrink: 0;
    svg {
      margin: 3px 2px 0 0;
    }
  }
}
.blend-flex-table__col {
	padding: 1rem;
	display: flex;
	flex-direction: column;

	.date {
		.text-base;
		.font-semibold;
		line-height: 1;
		color: fade(@color-grey, 50%);
		margin: 0.25rem 0 0;
		display: none;
	}
}
.blend-flex-table__values {
	display: flex;
	> div {
		flex: 1;
	}
	> .val:not(.val--classification) {
		display: none;
	}
}


// Toggle UI
.blend-flex-table__toggle {
	background: #fff;
	.blend-flex-table__heading {
		padding: 0;
	}
}


/*-----------------------------------------------------------------------------------------
	/Blend flex table - States
-----------------------------------------------------------------------------------------*/
.blend-flex-table[data-active="3in1"] {
	.col-3in1 {
		grid-column: span 7;
		.blend-flex-table-header__preview,
		.blend-flex-table-header__label .icon,
		.blend-flex-table-targets__preview {
			display: none;
		}
		.blend-flex-table-header__units,
		.blend-flex-table-targets__units {
			display: flex;
		}
		.date,
		.blend-flex-table__values > * {
			display: block;
		}
    .blend-flex-table-header__label {
      pointer-events: none;
    }
	}
	.col-mpi5 {
		grid-column: span 1;
		overflow: hidden;
    .blend-flex-table-header__label {
      .icon {
        order: -1;
        transform: rotate(90deg);
        margin: 0 0.25rem 0 -0.25rem;
      }
    }
	}
	.col-c4 {
		grid-column: span 1;
		overflow: hidden;
    .blend-flex-table-header__label {
      .icon {
        order: -1;
        transform: rotate(90deg);
        margin: 0 0.25rem 0 -0.25rem;
      }
    }
	}
}
.blend-flex-table[data-active="mpi5"] {
	.col-3in1 {
		grid-column: span 2;
	}
	.col-mpi5 {
		grid-column: span 6;
		.blend-flex-table-header__preview,
		.blend-flex-table-header__label .icon,
		.blend-flex-table-targets__preview {
			display: none;
		}
		.blend-flex-table-header__units,
		.blend-flex-table-targets__units {
			display: flex;
		}
		.date,
		.blend-flex-table__values > * {
			display: block;
		}
		.date {
			// margin-left: calc(100% / 6 * 1);
		}
    .blend-flex-table-header__label {
      pointer-events: none;
    }
	}
	.col-c4 {
		grid-column: span 1;
    .blend-flex-table-header__label {
      .icon {
        order: -1;
        transform: rotate(90deg);
        margin: 0 0.25rem 0 -0.25rem;
      }
    }
	}
}
.blend-flex-table[data-active="c4"] {
	.col-3in1 {
		grid-column: span 2;
	}
	.col-mpi5 {
		grid-column: span 1;
	}
	.col-c4 {
		grid-column: span 6;
		.blend-flex-table-header__preview,
		.blend-flex-table-header__label .icon,
		.blend-flex-table-targets__preview {
			display: none;
		}
		.blend-flex-table-header__units,
		.blend-flex-table-targets__units {
			display: flex;
		}
		.date,
    .blend-flex-table__values > * {
			display: block;
		}
    .blend-flex-table-header__label {
      pointer-events: none;
    }
	}
}


/*-----------------------------------------------------------------------------------------
	/Blending result
-----------------------------------------------------------------------------------------*/

.blend-results--default {
  .mb-8;

  .blend-flex-table__rows {
	  background: #fff;
	  padding-bottom: 1rem;
  }

  .blend-flex-table__col {
    &:last-child {
      margin-right: 1rem;
    }
  }

  .blend-result {
    color: @color-khaki;

    .blend-flex-table__heading {
      position: relative;
      margin-left: 0.875rem;
      padding: 0 0 0 2.125rem;

      .icon {
        width: 4rem;
        min-height: 4rem;
        height: 100%;
        margin: 0;
        .rounded-l;
        background: @color-yellow-light;
        flex-shrink: 0;
      }

      .text {
        flex: 1;
        height: 100%;
        background: @color-stone-light;

        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .title {
        .text-md;
        line-height: 1.2;
      }
    }

    .blend-flex-table__col {
      background: @color-stone-light;

      &:last-child {
        .rounded-r;
      }
    }
  }

  .blend-result--blend {
    .font-semibold;
    padding: 1rem 0;
    overflow: hidden;

    +.blend-flex-table__row {
      border-top: 0;
    }

    .blend-flex-table__heading {
      .text {
        border-top: 0.25rem solid @color-yellow-light;
        border-bottom: 0.25rem solid @color-yellow-light;
      }

      &:before {
        content: "";
        width: 0.25rem;
        position: absolute;
        left: 0;
        top: 50%;
        bottom: -1rem;
        background: @color-yellow-light;
      }

      &:after {
        content: "";
        width: 2rem;
        height: 2rem;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1rem;
        background: url('/images/icons/blend-result-parent.svg') no-repeat 50% / contain;
      }
    }

    .blend-flex-table__col {
      border-top: 0.25rem solid @color-yellow-light;
      border-bottom: 0.25rem solid @color-yellow-light;

      &:last-child {
        border-right: 0.25rem solid @color-yellow-light;
      }
    }

    .col-3in1,
    .col-mpi5 {
      &:after {
        top: -1.25rem;
        bottom: -1.25rem;
      }
    }
  }

  .blend-result--batch-summary {
    .font-semibold;

    .blend-flex-table__heading {
      &:before {
        content: "";
        width: 0.25rem;
        position: absolute;
        left: 0;
        top: -1px;
        bottom: 50%;
        background: @color-yellow-light;
      }

      &:after {
        content: "";
        width: 2.125rem;
        height: 0.25rem;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.125rem;
        background: @color-yellow-light;
      }
    }

    .inventory-item__unit-row {
      padding-top: 0.25rem;
    }

    .inventory-item__unit-qty {
      .text-base;
      text-align: left;
      min-width: auto;
    }

    .inventory-item__unit-blocks {
      padding-top: 0.35rem;
    }

    .inventory-item__unit-block {
      background: fade(@color-khaki, 15%);
      border-color: fade(@color-khaki, 50%);
    }
  }

  .blend-result--batch {
    display: none;
    border-top: 1px solid #fff;

    .blend-flex-table__heading {
      &:before {
        content: "";
        width: 0.25rem;
        position: absolute;
        left: 0;
        top: -1px;
        bottom: 0;
        background: @color-yellow-light;
      }

      &:after {
        content: "";
        width: 2.125rem;
        height: 0.25rem;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.125rem;
        background: @color-yellow-light;
      }

    }

    &:last-child {
      .blend-flex-table__heading:before {
        bottom: 50%;
      }
    }
  }
}

.blend-results--not-used {
	.blend-flex-table__heading {
		opacity: 0.5;
		padding-left: 3rem;
	}
}

.blend-results__toggle {
	.text-base;
	.font-semibold;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 1.375rem 3rem;
	svg {
		margin-right: 0.5rem;
		opacity: 0.4;
		transition: opacity .15s;
	}
	&:hover,
	&:focus {
		svg {
			opacity: 1;
		}
	}
	&.active {
		transform: rotate(180deg);
	}
}


.blend-results.is-expanded {
	.blend-result--batch-summary {
		display: none;
	}
	.blend-result--batch {
		display: grid;
	}
}




/*-----------------------------------------------------------------------------------------
	/Blend in progress
-----------------------------------------------------------------------------------------*/
.blend-in-progress__icon {
	svg {
		animation: spin 1s infinite linear;
	}
}




/*-----------------------------------------------------------------------------------------
	/Blends list
-----------------------------------------------------------------------------------------*/
.page--blends {
	.progress-steps__item {
		&:before {
			margin: 0;
		}
	}
	.blend-flex-table__heading {
		.checkbox {
			display: none;
		}
  }
  .blend-flex-table-targets {
    .title {
      display: block;
    }
  }
}

.blend-settings {
	width: 100%;
	height: 4.5rem;
	display: flex;
	align-items: center;
}
.blend-settings__toggle {
	.font-semibold;
	.rounded;
	display: flex;
	align-items: center;
	padding: 0.8125rem 1.5rem 0.8125rem 1rem;
	> * + * {
		margin-left: 1rem;
	}
	&:hover {
		background: fade(black, 5%);
	}
}
.blend-settings__toggle-icon {
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid @color-body;
	border-radius: 50%;
}

.blend-settings__secondary {
	display: flex;
	align-items: center;
	> * + * {
		margin-left: 1rem;
	}
}

.blend-settings__show-forecast {
	.switch-ui {
		.font-normal;
	}
}

.blend-settings__panel {
	background: #fff;
	padding: 5rem 4.5rem 6rem;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	height: calc(100vh - 12.5rem);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	display: none;
	&.is-active {
		display: flex;
	}
}
.blend-settings__panel-inner {
	max-width: 80rem;
	height: 100%;
	flex: 1;
}

.blend-settings__sidebar {
	display: flex;
	flex-direction: column;
}
.blend-settings-quantity {

	.bg-grey-10;
	.p-2;
	.mb-px;

	.blend-settings-quantity__field {
			.mt-1;
	}

	.blend-settings-quantity__label {
			.pb-0;
			.mb-0;
			.text-base;
	}

	.blend-settings-quantity__input {
			.bg-white;
			.border-white;
			padding: 0.4375rem 0.75rem;
	}
}
.blend-settings__footer {
	padding-top: 3rem;
	.toolbar-buttons {
		+ .toolbar-buttons {
			margin-top: 0.5rem;
		}
	}
	.button {
		width: 100%;
		margin-top: -1px;
	}
	.button_toolbar-item--highlighted {
		margin-top: 1px;
	}
}

.blend-settings__footer-output {
	.font-semibold;
	.text-base;
	margin: 0.8125rem 0 0;
}

.blend-settings-table {
	th,
	td {
		.bg-grey-light;
		.px-3;
		.py-1;
		border: 0;
		border-top: 1px solid #fff;
	}
	th {
		.text-base;
		.font-semibold;
	}
	td {
		.text-md;
		vertical-align: middle;
	}
	tbody tr {
		height: 3.5rem;
	}
	.col-limit {
		.font-semibold;
		width: 25%;
	}
	.col-value {
		width: 50%;
		.value-only {
			padding-left: 5.5rem;
		}
	}
	.col-cost {
		width: 25%;
		input[type="number"],
		input[type="text"] {
			width: 6rem;
		}
	}
  tr.is-populated {
    td {
      .text-blue;
		  background: tint(@color-blue, 85%);
    }
    .button_stepper {
      color: currentColor;
      background: tint(@color-blue, 85%);
      &:hover,
      &:focus {
        background: tint(@color-blue, 90%);
      }
    }
  }
	tr.is-disabled {
		cursor: default;
		td {
			.text-body;
			.bg-grey-light;
			> * {
				opacity: 0.5;
			}
		}
		.button_stepper {
			.bg-grey-light;
			cursor: default;
		}
	}
	input[type="text"],
	input[type="number"] {
		border: 0;
		background: #fff;
		text-align: right;
	}
}

.blend-settings-table__value {
	.symbol {
		width: 1.5rem;
		text-align: right;
		display: inline-block;
		vertical-align: middle;
	}
	.input-stepper {
		width: 12rem;
		margin: 0 0.5rem;
	}
	.input-stepper__field {
		flex-basis: 100%;
		max-width: 100%;
	}
	.button_stepper {

	}
}

.blend-settings__actions {
	padding: 0 0 4rem;
}

.blend-inventory__item {
	.checkbox {
		margin: 0.5rem 0.75rem 0 0;
	}
	.checkbox__label {
		margin: 0;
	}

	&.is-selected {
		background: tint(@color-blue, 85%);
    	box-shadow: inset 0 1px 0 0 #8fc6e5, inset -1px 0 0 #8fc6e5, inset 0 -1px 0 0 #8fc6e5, inset 1px 0 0 #8fc6e5;
	}
}


.floral-indicator {
	background: fade(@color-green, 15%);
	border-radius: 1rem;
	height: 32px;
	display: inline-flex;
	.col {
	  position: relative;
	  z-index: 1;
	  appearance: none;
	  border: 0;
	  padding: 0;
	  background: 0;
	  color: fade(@color-green, 30%);
	  border-radius: 0 1rem 1rem 0;
	  margin-left: -12px;
	  padding-left: 12px;
	  display: flex;
	  align-items: center;
	  justify-content: flex-end;
	  min-width: calc(32px + 5px);
	  &:focus {
		outline: 0;
	  }
	}
	.col:first-child {
	  width: calc(32px + 56px);
	  border-radius: 1rem;
	  margin-left: 0;
	  padding-left: 0;
	  z-index: 3;
	}
	.col:nth-child(2) {
	  z-index: 2;
	}
	.icon {
	  width: 2rem;
	  height: 2rem;
	  margin: 0 0 0 0.5rem;
	  border-radius: 1rem;
	  background: 0;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  svg {
		  width: 1.5rem;
		  height: 1.5rem;
	  }
	}
  }
  [data-active="flowers"] {
	.col:first-child {
	  background: @color-green;
	  color: #fff;
	}
  }
  [data-active="multifloral"] {
	.col:first-child,
	.col:nth-child(2) {
	  background: @color-green;
	  color: #fff;
	}
  }
  [data-active="monofloral"] {
	.col {
	  background: @color-green;
      color: #fff;
	}
  }



/*-----------------------------------------------------------------------------------------
	/Summary
-----------------------------------------------------------------------------------------*/
.page--blends-summary {
  .blend-flex-table__heading {
		.checkbox {
			display: none;
		}
  }
  .blend-flex-table-targets {
    .col-unit {
      display: none;
    }
    .subheading {
      .text-body;
    }
  }
}
