/*  ----------------------------------------------------------------------------
   	/No supported property
   	---------------------------------------------------------------------------- */

.no-flexbox{
	.row > *{
		.inline-block;
	}
}

.no-csspositionsticky{
	// work with stickybits js
	.site-sidebar{
		position: relative;

		.site-sidebar__inner{
			top: auto;
			left: 0;
		}
	}
}




/*  ----------------------------------------------------------------------------
   	/IE
   	---------------------------------------------------------------------------- */

.lt-ie9 {

}
.lt-ie8 {

}
