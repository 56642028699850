
button {
	-webkit-border-radius: 0;
	-webkit-appearance: none;
	padding: 0;
	background: transparent;
	border: 0;
	cursor: pointer;
	font-family: inherit;
	transition: .15s;
}

.button,
.content-block .button,
[type="reset"],
[type="submit"] {
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-decoration: none;
	padding: 0.8125rem @s-3;
	.inline-flex;
	.items-center;
	.justify-center;
	.mt-2;
	.bg-yellow;
	.border;
	.border-solid;
	.border-yellow;
	.rounded;
	.text-black;
	.font-semibold;
	.font-smoothing;
	.font-size-height(15,12);
	cursor: pointer;
	transition: .15s;

	&:focus{
		outline: none;
	}

	&:hover{
		.bg-orange;
		.border-orange;
		.text-black;
	}

	.txt {
		.inline-block;
	}

	.type-icon{
		.inline-block;
		margin-top: -.125rem;
		.line-height(20);
	}

	.txt + .type-icon {
		margin-left: .625rem;
	}

	svg {
		.-ml-half;
		.mr-2;
	}
}

button[disabled],
html input[disabled] {
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	filter: alpha(opacity=40);
	.bg-grey-10;
	.border-transparent;
	.text-grey-40;
  cursor: default;
  pointer-events: none;

	&:hover{
		.bg-grey-10;
		.border-transparent;
		.text-grey-40;
	}
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.button {
	overflow: visible;
	text-transform: none;
}

.button_happy {
	background: @color-green;
	border-color: @color-green;
	.text-white;
}

.button_add {
	.bg-blue;
	.border-blue;
	.text-white;

	&:hover {
		background: shade(@color-blue, 10%);
		border-color: shade(@color-blue, 10%);
		.text-white;
	}
}

.button_delete {
	.bg-red;
	.border-red;
	color: #fff;
}

.button_next {
	.flex;
	.justify-center;
	.items-center;
	height: @s-5;
	svg {
		.ml-1;
		.mr-0;
	}
}

.button_prev {
  .flex;
  .justify-center;
  .items-center;
  .w-9;
  .h-5;
  .px-1;
  .bg-transparent;
	.text-body;
	border-color: tint(@color-body, 70%);
	&:hover,
	&:focus {
		background: fade(black, 2.5%);
		border-color: inherit;
	}
  svg {
    margin: 0;
    transform: scaleX(-1);
  }
}

.button_stepper {
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	.w-4;
	.h-4;
	line-height: @s-4;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	.bg-grey-divider;
	text-align: center;
	.font-semibold;
	font-size: 1.5rem;
	.text-grey-metal;

	&:hover {
		background: shade(#DEDEDE, 10%);
	}
	svg {
		margin: 0;
	}
}

.button_stepper--big {
	.w-5;
	.h-5;
	line-height: @s-5;
	font-size: 1.5rem;
}

.button_round {
	width: 1.875rem;
	height: 1.875rem;
	padding: .1875rem;
	border-radius: 50%;
	text-align: center;
	line-height: 1;
}

.button_outline{
	.bg-transparent;
	.border-body;
	.text-body;
}

.button_grey {
	.text-body;
	border-color: #E1E1E1;
	background: #E1E1E1;
	&:hover {
		.bg-grey-light;
		.border-grey-divider;
		.text-body;
	}
}

.button_black {
	color: #FFF;
	border-color: #000;
	background: #000;
	&:hover {
		color: #FFF;
		border-color: #000;
		background: #000;
		opacity: 0.9;
	}
}

.button_toolbar-item {
	.bg-transparent;
	.text-body;
	.text-base;
	.uppercase;
	.justify-start;
	.px-2;
	border-color: tint(@color-body, 70%);
	svg {
		.mr-1;
	}
	&:hover,
	&:focus {
		background: fade(black, 2.5%);
		border-color: inherit;
	}
}
.button_toolbar-item--highlighted {
	color: @color-blue;
	background: tint(@color-blue, 85%);
	border-color: transparent;
	&:hover,
	&:focus {
		color: #fff;
		background: @color-blue;
	}
}

.button_big {
	padding: .75rem 1.75rem .875rem;
}

.button_invert {
	.bg-white;
	.border-white;
}

.button_link{
	background: none;
	border-color: transparent;
	padding-bottom: .5rem;

	.txt{
		border-bottom: 1px solid @color-body;
		color: @color-body;
		transform: border-bottom-color 180ms linear, color 180ms linear;
	}

	&:hover{
		background: none;
		border-color: transparent;

		.txt{
			border-bottom-color: @color-black;
			color: @color-black;
		}
	}
}

.button_new-composite-group {
	border: 1px dashed @color-blue;
	.text-blue;
	.font-semibold;
	.p-2;
}

.button .icon + .txt {
	margin-left: .625rem;
}
.button .txt + .icon {
	margin-left: .625rem;
}

.button_large-icon {
	svg {
		.-m-1;
		.mr-1;
	}
}

.button_open-filters {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &[disabled] {
    background: inherit;
    opacity: 0.3;
  }
}


.button-icon{
	.inline-block;
	margin: 0 -.625rem;
	padding: .625rem;

	.txt{
		.inline-block;
		padding-top: .25rem;
		padding-bottom: .375rem;
		.small-uppercase;
	}

	.type-icon{
		.inline-block;
		padding-top: .125rem;
		padding-bottom: .5rem;
		.line-height(20);
	}

	.txt + .type-icon{ padding-left: .25rem; }
	.type-icon + .txt{ padding-left: .25rem; }

	&.button-icon_grey{
		.text-body;
		opacity: .75;
		transition: opacity 200ms linear;

		&:hover{ opacity: 1; }
	}
}


.button-group {
	.no-bullets;
	display: flex;
	margin: 0 -.1875rem;

	> li {
		padding: .375rem .1875rem;
	}
	.button {
		margin: 0;
	}
}

.button-group--centred {
	justify-content: center;
}

.button-group--tight {
	margin: 0;
	> li {
		margin-left: -1px;
		padding: 0;
	}
	> li:first-child {
		.button {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	> li + li {
		.button {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}


.toolbar-buttons {
	.button {
		.m-0;
	}
}

.button_ghost,
.button_reorder {
	.flex;
	.items-center;
	.bg-transparent;
	.text-body;
	.leading-lg;
	.justify-start;
	.border;
  .border-grey-divider;
	.py-1;
	.px-2;
	svg {
		.mr-1;
	}
	&:hover,
	&:focus {
		.border-grey-40;
		background: inherit;
	}
}

.button_create-blend {
	.icon {
		width: 1.5rem;
		height: 1.5rem;
		margin-top: -0.5rem;
		margin-bottom: -0.5rem;
		margin-left: -0.5rem;
	}
	.icon svg {
		margin: 0;
	}
}
