

/*  --------------------------------------------------------------------------------
   	/Grid variables
   	----------------------------------------------------------------------------- */

@half-gutter-width:		.1875rem;
@gutter-compensation:	0;
@outer-margin-l:		4.6875rem;
@outer-margin-r:		1.6875rem;
@grid-columns:          14;



@col-names: s, m, l, xl, xxl;
@col-breaks: @s-break, @m-break, @l-break, @xl-break, @xxl-break;




/*  --------------------------------------------------------------------------------
    / Grid class names

   	.row
    .col-sm-1
    .col-sm-offset-1
   	----------------------------------------------------------------------------- */




/*  --------------------------------------------------------------------------------
   	/Grid Mixins
   	----------------------------------------------------------------------------- */

// .for
.for(@i, @n) {.-each(@i)}
.for(@n)     when (isnumber(@n)) {.for(1, @n)}
.for(@i, @n) when not (@i = @n)  {
    .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

// .for-each
.for-each(@array)   when (default()) {.for-impl_(length(@array))}
.for-impl_(@i) when (@i > 1)    {.for-impl_((@i - 1))}
.for-impl_(@i) when (@i > 0)    {.-each(extract(@array, @i))}


.makegrid(@class) {
    [class^='col-@{class}'],
    [class*='col-@{class}'],
    [class^='col-@{class}-offset'],
    [class*='col-@{class}-offset']{
    	flex: 0 0 auto;
    	flex-direction: column;
    	padding-right: (@half-gutter-width/2);
    	padding-left: (@half-gutter-width/2);

        @media (min-width: @l-break) {
            padding-right: @half-gutter-width;
        	padding-left: @half-gutter-width;
        }
    }

    .col-@{class}-0{
        padding-right: 0;
        padding-left: 0;
    }

    .col-@{class} {
    	flex-grow: 1;
    	flex-basis: 0;
    	max-width: 100%;
    }

    .make-cols(@class);
    .make-offset(@class; 0);
    .make-offsets(@class);
    .make-position-classes(@class);
}

.make-column(@class; @col-number) {
	.col-@{class}-@{col-number} {
		flex-basis: ((100% / @grid-columns) * @col-number);
		max-width: ((100% / @grid-columns) * @col-number);
	}

	.no-flexboxlegacy .col-@{class}-@{col-number} {
		width: ((100% / @grid-columns) * @col-number);
	}
}

.make-cols(@class; @col-number: @grid-columns) when (@col-number > 0) {
    .make-cols(@class; (@col-number - 1));
    .make-column(@class; @col-number);
}

.make-offset(@class; @col-number) {
    .col-@{class}-offset-@{col-number} {
    	margin-left: ((100% / @grid-columns) * @col-number);
    }
}

.make-offsets(@class; @col-number: (@grid-columns - 1)) when (@col-number > 0) {
    .make-offsets(@class; (@col-number - 1));
    .make-offset(@class; @col-number);
}

.make-position-classes(@class) {
    .start-@{class} {
        justify-content: flex-start;
    	text-align: start;
    }
    .center-@{class} {
    	justify-content: center;
    	text-align: center;
    }
    .end-@{class} {
        justify-content: flex-end;
    	text-align: end;
    }
    .top-@{class} {
    	align-items: flex-start;
    }
    .middle-@{class} {
    	align-items: center;
    }
    .bottom-@{class} {
    	align-items: flex-end;
    }
    .around-@{class} {
        justify-content: space-around;
    }
    .between-@{class} {
        justify-content: space-between;
    }
    .first-@{class} {
    	order: -1;
    }
    .last-@{class} {
    	order: 1;
    }
}



 /* ----------------------------------------------------------------------------
	/Grid
	----------------------------------------------------------------------------- */

.grid {
    padding-right: (@outer-margin-r/2);
    padding-left: (@outer-margin-l/2);

    @media (min-width: @l-break) {
        padding-right: @outer-margin-r;
    	padding-left: @outer-margin-l;
    }
}
.row {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
	margin: 0 auto;

    @media (min-width: @s-break) {
        margin-right: @gutter-compensation;
    	margin-left: @gutter-compensation;
    }
}

.makegrid(xs);

@media only screen and (min-width: @s-break) {
  .makegrid(s);
}

@media only screen and (min-width: @m-break) {
  .makegrid(m);
}

@media only screen and (min-width: @l-break) {
  .makegrid(l);
}

@media only screen and (min-width: @xl-break){
    .makegrid(xl);
}

@media only screen and (min-width: @xxl-break){
    .makegrid(xxl);
}

/*.for-each(@col-breaks);
.-each(@breakpoint) {
	@media only screen and (min-width: @breakpoint) {
		.makegrid(extract(@col-names, @i));
	}
}*/
