/* DayPicker styles */

.DayPicker {
	display: inline-block;
	width: 100%;
	.bg-white;
}

.DayPicker-wrapper {
	position: relative;
	flex-direction: row;
	user-select: none;
	.my-6;
}

.DayPicker-Months {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.DayPicker-Month {
	display: table;
	.m-2;
	border-spacing: 0;
	border-collapse: collapse;
	user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
	position: absolute;
	top:  0;
	right: 3.2rem;
	left: auto;
	display: inline-block;
	.w-5;
	.h-5;
	border: 1px solid tint(@color-body, 80%);
	background-position: center;
	background-size: 50%;
	background-repeat: no-repeat;
	background-size: 0.625rem 1rem;
	cursor: pointer;
	transition: .1s;
}

.DayPicker-NavButton:hover {
	opacity: 0.8;
}

.DayPicker-NavButton--prev {
	margin-right: calc(@s-5 - 1px);
	.rounded-l;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSAxNUwyIDhsNy03IiBzdHJva2U9IiM1ODU5NUIiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==');
}

.DayPicker-NavButton--next {
	.rounded-r;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxbDcgNy03IDciIHN0cm9rZT0iIzU4NTk1QiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+');
}

.DayPicker-NavButton--interactionDisabled {
	display: none;
}

.DayPicker-Caption {
	display: table-caption;
	margin: -0.5rem 0 1rem;
	text-align: left;
}

.DayPicker-Caption > div {
	.font-normal;
	.text-xl;
}

.DayPicker-Weekdays {
	display: table-header-group;
	margin-top: 1em;
}

.DayPicker-WeekdaysRow {
	display: table-row;
}

.DayPicker-Weekday {
	display: table-cell;
	padding: 0.5em;
	text-align: center;
	.text-base;
	.font-semibold;
}

.DayPicker-Weekday abbr[title] {
	border-bottom: none;
	text-decoration: none;
}

.DayPicker-Body {
	display: table-row-group;
}

.DayPicker-Week {
	display: table-row;
}

.DayPicker-Day {
	display: table-cell;
	padding: 0.5em;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	.w-5;
	.h-5;
}

.DayPicker-WeekNumber {
	display: table-cell;
	padding: 0.5em;
	min-width: 1em;
	border-right: 1px solid #EAECEC;
	color: #8B9898;
	vertical-align: middle;
	text-align: right;
	font-size: 0.75em;
	cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
	cursor: default;
}

.DayPicker-Footer {
	padding-top: 0.5em;
}

.DayPicker-TodayButton {
	border: none;
	background-color: transparent;
	background-image: none;
	box-shadow: none;
	color: #4A90E2;
	font-size: 0.875em;
	cursor: pointer;
}

/* Modifiers */

.DayPicker-Day:not(.DayPicker-Day--outside) {
	border: 1px solid #dedede;
	transition: 100ms;
}

.DayPicker-Day--today {
	color: @color-orange;
	.font-semibold;
}

.DayPicker-Day--outside {
	cursor: default;
}

.DayPicker-Day--disabled {
	color: fade(@color-body, 50%);
	cursor: default;
	/* background-color: #eff1f1; */
}

.DayPicker-Day--sunday {
	background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
	color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	position: relative;

	background-color: @color-blue;
	border-color: shade(@color-blue, 20%);
	color: #fff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
	background-color: shade(@color-blue, 10%);
}

.DayPicker:not(.DayPicker--interactionDisabled)
	.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
	background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
	display: inline-block;
}

.DayPickerInput-OverlayWrapper {
	position: relative;
}

.DayPickerInput-Overlay {
	position: absolute;
	left: 0;
	z-index: 1;

	background: white;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
