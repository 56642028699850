

/*  ----------------------------------------------------------------------------
   	/Less variables / mixins
   	---------------------------------------------------------------------------- */


/* 	Timestamp
	---------------------------------------------------------------------------- */
@date: '2021-01-07-101902';
@timestamp: "?v=@{date}";



/* 	Fonts
	---------------------------------------------------------------------------- */
@font-code:					'courier new', monospace, serif;
@font-weight-code:			400;
@default-font-size:			1rem;			// 16px
@default-line-height:		1.5rem;			// 24px



/*  Colors
	---------------------------------------------------------------------------- */
@color-black       : #000;
@color-white       : #FFF;
@color-grey        : #58595B;
@color-grey-light  : tint(@color-grey, 90%);       // #EEEEEF
@color-body        : @color-grey;
@color-yellow      : #FFCB05;
@color-yellow-light: #F8E79E;
@color-orange      : #F18820;
@color-stone       : #F5EDD0;
@color-stone-light : #F7F2DD;
@color-khaki       : #6A6949;
@color-blue        : #007cbf;
@color-green       : #0E9D47;
@color-red         : #E21D1D;
@color-primary     : @color-orange;
@color-link        : @color-primary;
@color-link-hover  : darken(@color-primary, 20%);
@color-link-visited: purple;
@color-border      : fade(@color-body, 20%);



/*  Media queries
	---------------------------------------------------------------------------- */
@xxl-break: 	110em;		// 1760px
@xl-break: 		90em;		// 1440px
@l-break: 		70em;		// 1120px
@m-break: 		53em;		// 848px
@s-break: 		40em;		// 640px

@xl-break-up: 	(@xxl-break - .0625rem);
@l-break-up: 	(@xl-break - .0625rem);
@m-break-up: 	(@l-break - .0625rem);
@s-break-up: 	(@m-break - .0625rem);
@xs-break-up: 	(@s-break - .0625rem);

@xl-xxl-media:	~'(min-width: @{xl-break}) and (max-width: @{xl-break-up})';
@l-xl-media:	~'(min-width: @{l-break}) and (max-width: @{l-break-up})';
@m-l-media: 	~'(min-width: @{m-break}) and (max-width: @{m-break-up})';
@s-m-media: 	~'(min-width: @{s-break}) and (max-width: @{s-break-up})';
@xs-s-media:	~'(max-width: @{xs-break-up})';

@l-xxl-media:	~'(min-width: @{l-break}) and (max-width: @{xl-break-up})';
@m-xl-media: 	~'(min-width: @{m-break}) and (max-width: @{l-break-up})';
@s-l-media: 	~'(min-width: @{s-break}) and (max-width: @{m-break-up})';
@xs-m-media: 	~'(max-width: @{s-break-up})';

@m-xxl-media:	~'(min-width: @{m-break}) and (max-width: @{xl-break-up})';
@s-xl-media: 	~'(min-width: @{s-break}) and (max-width: @{l-break-up})';
@xs-l-media: 	~'(max-width: @{m-break-up})';

@s-xxl-media:	~'(min-width: @{s-break}) and (max-width: @{xl-break-up})';
@xs-xl-media: 	~'(max-width: @{l-break-up})';

@xs-xxl-media: 	~'(max-width: @{xl-break-up})';



/*  Spacing
	---------------------------------------------------------------------------- */

@s-px  : 1px;      // 1px
@s-half: 0.25rem;  // 4
@s-1   : 0.5rem;      // 8
@s-2   : 1rem;     // 16
@s-3   : 1.5rem;   // 24
@s-4   : 2rem;     // 32
@s-5   : 2.5rem;   // 40
@s-6   : 3rem;     // 48
@s-7   : 3.5rem;   // 56
@s-8   : 4rem;     // 64
@s-9   : 4.5rem;   // 72
@s-10  : 5rem;     // 80
@s-11  : 5.5rem;   // 88
@s-12  : 6rem;     // 96
@s-13  : 6.5rem;   // 104
@s-14  : 7rem;     // 112
@s-15  : 7.5rem;   // 120
@s-16  : 8rem;     // 128
@s-17  : 8.5rem;   // 136
@s-19  : 9rem;     // 144
@s-20  : 9.5rem;   // 152
@s-22  : 11rem;    // 176
@s-24  : 12rem;    // 192
@s-32  : 16rem;    // 256
@s-40  : 20rem;    // 320




/*  Forms
	---------------------------------------------------------------------------- */
@placeholder-color:			fade(@color-body, 50%);
@field-color:				@color-body;
@field-bg-color:			fade(@color-body, 10%);
@field-border-color:		fade(@color-body, 10%);
@field-border-color-focus:	@color-yellow;
@field-padding:				.6875rem .625rem;
@field-padding-m:			.8125rem 1.25rem;



/*  Easings
	---------------------------------------------------------------------------- */
@ease-in-out-back: 			cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
@ease-in-out-quint: 		cubic-bezier(0.86, 0, 0.07, 1);
@ease-out-expo: 			cubic-bezier(0.19, 1, 0.22, 1);
@ease:						cubic-bezier(0.4, 0.1, 0, 1);



/*  animations
	---------------------------------------------------------------------------- */
@keyframes loading-spin{
	0%{ transform:rotate(-45deg); }
	100%{ transform:rotate(315deg); }
}

@keyframes fadeIn {
	0% {
			opacity: 0;
	}

	100% {
			opacity: 1;
	}
}


@topbar-height: 2.5rem;
