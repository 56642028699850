
/*  ----------------------------------------------------------------------------
   	/Forms
   	---------------------------------------------------------------------------- */



/*  Form, Fieldset and Legend
   	---------------------------------------------------------------------------- */

fieldset,
legend {
	border: 0;
	/*margin: 0;
	padding: 0;*/
}

/*
 * Change the border, margin, and padding in all browsers (opinionated).
*/
fieldset {
	.border-b;
	.border-solid;
	.border-grey-divider;
	// border-bottom: 1px solid @color-border;
	.mx-0;
	.my-6;
	padding: 2.25rem 0 2.1875rem;
}
legend {
	// padding-top: .4375rem;
	// padding-bottom: .1875rem;
	.font-body;
	font-style: normal;
	.text-black;
}
.form {
	.py-3;
}



/*  All Form Elements
   	---------------------------------------------------------------------------- */

button,
input,
optgroup,
select,
textarea {
	color: @field-color;
	font: inherit;
	margin: 0;
}



/*  Inputs
   	---------------------------------------------------------------------------- */

input { line-height: normal; }

input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea,
select{
	.bg-grey-10;
	-webkit-appearance: none;
	-moz-appearance: textfield;
	-webkit-border-radius: 0;
	border: 0;
	.border;
	.border-solid;
	.border-grey-10;
	.rounded;
	.font-size-height(16, 24);

	.fields-list--no-bg & {
		.bg-white;
	}

}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="date"] {
	display: block;
	vertical-align: top;
	height: auto;
	width: 100%;
	padding: .6875rem 1.0625rem .6875rem;

	&:focus {
		border-color: @field-border-color-focus;
		outline: 0;
	}
	&[disabled]{
		cursor: not-allowed;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-box-shadow: 0 0 0px 200em @color-white inset;
	}

}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="date"]::-webkit-inner-spin-button {
	display: none;
	-webkit-appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;

	&:focus{
		outline: 1px dotted fade(@color-body, 50%);
		outline-offset: .25rem;
	}
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
input[type="search"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

input[type="file"]{
	padding: @field-padding;
	background-color: @field-bg-color;
	width: 100%;
	.font-normal;
}



/*  Text Area
   	---------------------------------------------------------------------------- */

textarea {
	display: block;
	width: 100%;
	overflow: auto;	// Safari fix
	padding: .6875rem 1.125rem .6875rem;

	resize: vertical;

	&:focus {
		border-color: @field-border-color-focus;
		outline: 0;
	}
}



/*  Select
   	---------------------------------------------------------------------------- */

select {
	   -moz-appearance: none;
	-webkit-appearance: none;
	    -ms-appearance: none;
	        appearance: none;
	text-transform: none;
	box-shadow: none;
	height: auto;
	width: 100%;
	padding: .6875rem 1.125rem .6875rem;
	cursor: pointer;

	&:focus {
		background-color: @field-border-color-focus;
		outline: 0;
	}
}

optgroup { font-weight: normal; }

.select {
	position: relative;

	&:after {
		content: "";
		background: url('/images/icons/arrow-down.svg') no-repeat 50% / contain;
		position: absolute;
		top: 50%;
		right: 1rem;
		width: 1rem;
		height: 1rem;
		margin-top: -0.5rem;
		opacity: .4;
		pointer-events: none;
		transition: opacity 200ms linear;
	}

	&:hover {
		&:after {
			opacity: 1;
		}
	}
}



/*  Buttons
   	---------------------------------------------------------------------------- */


/*  label
   	---------------------------------------------------------------------------- */

label,
.label {
	display: block;
	.mb-1;
	.font-semibold;
	.font-size-height(15,24);
}




/*  Placeholder Colour
   	---------------------------------------------------------------------------- */

::-webkit-input-placeholder { color: @placeholder-color; }
:-moz-placeholder { color: @placeholder-color; }
::-moz-placeholder { color: @placeholder-color; }
:-ms-input-placeholder { color: @placeholder-color; }




/*  Classes
   	---------------------------------------------------------------------------- */

.content-block .fields-list{
	& > li{
		&:before{ content: none; }
	}
}

.field{
	.mt-2;
}

.field-text{
	&--icon-right{
		position: relative;

		.input-txt{
			padding-right: @s-9;
		}

		.field-text__icon{
			position: absolute;
			right: @s-3;
			bottom: @s-2;
			pointer-events: none;

			.icon__truck-small{ margin-bottom: @s-half; }
		}
	}
}

.field-checkbox,
.field-radio{
	display: flex;
	align-items: baseline;
	.mt-2;

	label {
		margin: 0;
		padding: 0 0 0 .625rem;
		text-transform: none;
	}

	input{
		width: auto;
		position: relative;
		top: 0.125rem;
	}
}

.field-phone {
	.fields-list {
		display: flex;

		.field-country,
		.field-tel {
			margin-top: 0;
			label {
				.visuallyhidden;
			}
		}
		.field-country {
			width: 50%;
			@media (min-width: @xl-break) {
				width: 30%;
			}
		}
		.field-tel {
			width: 50%;
			.pl-1;
			@media (min-width: @xl-break) {
				width: 70%;
			}
		}
	}
}


.field-addr {
	.fields-list {
		display: flex;
	}
	.field-suburb,
	.field-postcode {
		margin-top: 0;
	}
	.field-postcode {
		width: 30%;
	}
	.field-suburb {
		width: 70%;
		.pr-1;
	}
}

.field-date {
	&__input{
		width: 100%;
		.p-1;
		.bg-grey-10;
		border: 0;
		.rounded;
		.text-left;
		.font-size-height(16, 24);
	}

	&__text{
		flex-grow: 2;
		text-align: left;
	}

	&__icon{
		.icon__calendar{ .my-half; }
	}
}

.field-select {

	&__input{
		.relative;
		width: 100%;
		.py-1;
		.px-2;
		.bg-grey-10;
		border: 0;
		.rounded;
		.text-left;
		.font-size-height(16, 24);
	}

	&__list{
		.flex;
		.flex-wrap;
	}

	&__item{
		.p-half;
	}
	&__icon {
		position: absolute;
		right: @s-2;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}
}


.field-multiselect {

	&__input{
		.relative;
		width: 100%;
		.py-1;
		.pl-2;
		.pr-6;
		.bg-grey-10;
		border: 0;
		.rounded;
		.text-left;
		.font-size-height(16, 24);
	}

	&__list{
		.flex;
		.flex-wrap;
	}

	&__item{
		.p-half;
	}
}

.field-password {
	width: 100%;

	@media (min-width: @m-break) {
		width: 50%;
		.pr-1;

		+ .field-password {
			.pr-0;
		}
	}

	@media (min-width: @l-break) {
		width: 24%;
		.pr-1;

		+ .field-password {
			width: 26%;
			.pr-3;
		}
	}
}

#password_reset_form {
	.field-password {
		width: 100%;
		padding: 0;
	}
}



/*  react-select
---------------------------------------------------------------------------- */
.react-select {
	flex: 1;

	.react-select__control {
		min-height: 0;
		border: 0;
		border-radius: 0;
		background: 0;
		box-shadow: none;
	}
	.react-select__input {
		.text-md;
	}
	.react-select__value-container {
		padding: 0;
	}
	.react-select__value-container--is-multi.react-select__value-container--has-value {
		margin-left: -0.75rem;
		margin-top: -0.25rem;
		margin-bottom: -0.25rem;
	}
	.react-select__indicators {
		display: none;
	}
	.react-select__multi-value {
		.bg-body;
		.rounded;
		.m-half;
		.mr-0;
		.pr-half;
		.items-center;
	}
	.react-select__multi-value__label {
		color: #fff;
		.text-base;
		.font-semibold;
		.p-half;
		.pl-1;
	}
	.react-select__multi-value__remove {
		.w-3;
		.h-3;
		.p-0;
		.flex;
		.items-center;
		.justify-center;
		.text-white;
		cursor: pointer;

		&:hover,
		&:focus {
			background: 0;
			.text-white;
		}
	}
	.react-select__placeholder {
		.text-md;
		.text-body;
		.ml-0;
	}
	.react-select__menu {
		.-ml-2;
	}
	.react-select__menu-list {
		.p-0;
	}
	.react-select__option {
		.text-md;
		.text-body;
		.p-1;
		.px-2;
	}
	.react-select__option--is-focused {
		.bg-stone;
	}
}


/*  tag item
   	---------------------------------------------------------------------------- */

/*	<div class="tag-item [tag-item--white, tag-item--green, tag-item--orange, tag-item--red]">
		<span class="tag-item__text">[…]</span>
		{% include 'icons/icon__close.svg' %}
	</div>
*/


.tag-item{
	.flex;
	.items-center;
	padding: @s-half @s-half @s-half @s-2;
	background-color: @color-body;
	.rounded;
	.font-smoothing;
	color: @color-white;

	&__text{
		display: inline-block;
		.font-semibold;
		.font-size-height(11,12);
	}

	&.tag-item--white{
		background-color: @color-white;
		.font-smoothing(auto);
		color: @color-body;
	}

	&.tag-item--green{
		background-color: @color-green;
	}

	&.tag-item--orange{
		background-color: @color-orange;
	}

	&.tag-item--red{
		background-color: @color-red;
	}
}


.tag-group{
	.flex;
	.flex-wrap;
	.-m-half;

	.tag-group__item{
		.p-half;
	}
}







/*----------------------------------------------------------------------------
 	/Validation
---------------------------------------------------------------------------- */
.validationErrors {
    .my-1;
	list-style-type: none;
	width: 100%;

}
.formValidationError {
	.font-semibold;
	.font-size-height(11,12);
	.text-red;
}

.validation-errors {
    display: flex;
    width: 100%;
    color: @color-red;

	.validation-errors__list{
		display: block;
		.m-0;
		padding: @s-1 0 @s-2;
	}

	.validation-errors__item{
		display: flex;

		&:before{ content: none; }

		.txt{
			.h6;
			.mb-0;
			.text-red;
		}
	}
}


.input-label {
	position: absolute;
	top: 1rem;
	left: 1.2rem;
}

.inline-labels {
	li {
		position: relative;
		margin-bottom: 0;
	}
	.input-txt {
		padding-left: 9rem;
	}
}

.textarea-area {
	textarea {
		padding-top: 3rem;
	}
}



.ui-radio{
	display: flex;
	max-width: ~'calc( 27.5rem + 6px )';

	.ui-radio__field{
		flex-basis: 33.333%;
		max-width: 33.333%;


		& + .ui-radio__field{
			margin-left: -3px;
		}
	}

	.ui-radio__label{
		position: relative;
		margin: 0;
		padding: 1rem 1.25rem 1.125rem;
		border: 3px solid @color-border;
		text-align: center;
		cursor: pointer;
		transition: border-color 100ms linear;

		&:hover{
			z-index: 5;
			border-color: lighten(@color-body, 40%);
		}
	}

	.radio:checked + .ui-radio__label{
		z-index: 10;
		.border-orange;
		.text-orange;
	}

	.radio:focus + .ui-radio__label{
		z-index: 5;
		border-color: @color-link-hover;
		color: @color-link-hover;
	}
}

.ui-slider{
	position: relative;
	padding-bottom: 1.875rem;

	.ui-slider{ padding-bottom: 0; }

	.ui-slider__bar{
		position: relative;
		display: block;
		height: 1.25rem;
		width: 100%;
		margin-top: 1.25rem;
		background-color: fade(@color-body, 10%);
		cursor: col-resize;
	}

	.ui-slider-handle{
		position: absolute;
		z-index: 2;
		display: block;
		height: 2.5rem;
		width: 1.25rem;
		padding: 0 .5625rem;
		margin: -.625rem 0 0 -.625rem;
		white-space: nowrap;
		text-align: center;
		.small-uppercase;
		.text-orange;
		cursor: col-resize;
		touch-action: none;

		&:before{
			content: '';
			display: block;
			height: 2.5rem;
			width: .125rem;
			.bg-orange;
		}

		.txt{
			display: block;
			width: 3.75rem;
			margin-top: -.125rem;
			margin-left: -1.875rem;
		}
	}

	.ui-slider-range{
		display: block;
		height: 100%;
		.bg-orange;
	}

	.ui-slider__value{
		position: absolute;
		display: block;
		width: 2.5rem;
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		padding-top: .5rem;
		text-align: center;
		.small-uppercase;
		.text-grey-60;

		&.ui-slider__value_min{}
		&.ui-slider__value_max{ right: 0; }
	}
}

.field.field_inline{
	display: flex;
	flex-direction: row;

	.label{
		flex-basis: 33.333%;
		max-width: 33.333%;
		padding-right: 1.25rem;
	}

	.ui-radio{
		flex-grow: 2;
	}

	.ui-slider{
		flex-grow: 2;
		max-width: 27.5rem;
	}

	&.field-choice{
		padding-bottom: .625rem;

		.label{
			align-self: center;
			margin-top: 0;
			margin-bottom: 0;
			padding-top: .25rem;
			padding-bottom: .375rem;
		}
	}

	&.field-bar{
		.label{
			padding-top: .5625rem;
			padding-bottom: .6875rem;
		}
	}
}



.choice-item {
	background: #fff;

	label {
		position: relative;
		padding-right: 3rem;
		.border;
		.border-solid;
		.border-grey-divider;
		border-bottom-color: transparent;
		margin: 0;
		transition: .15s;

		&:before {
			content: "";
			position: absolute;
			transform: translateY(-50%);
			top: 50%;
			right: 1.125rem;
			width: .875rem;
			height: .875rem;
			background: #fff url('/images/icons/tickbox-tick.svg') no-repeat 50%;
			border-width: 2px;
			.border-solid;
			.border-grey-30;
			border-radius: 2px;
			transition: .1s;
		}
	}

	&:last-child {
		label {
			border-bottom-color: fade(@color-grey, 20%);
		}
	}

	input[type="checkbox"] {
	    position: absolute;
	    top: 0;
	    right: 0;
		opacity: 0;

		&:checked + label {
			border-color: #8cc2e1;
			background: fade(@color-blue, 20%);

			&:before {
				.bg-blue;
				.border-blue;
			}
		}
	}


}




.checkbox {
  position: relative;

  &__label {
    padding: 0;
    .w-2;
    .h-2;
    border: 2px solid fade(@color-body, 30%);
    transition: .15s;
    .rounded;
    .flex;
    .justify-center;
    .items-center;

    svg {
      opacity: 0;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &:checked {
      +label {
        .bg-blue;
        .border-blue;
        .text-white;

        svg {
          opacity: 1;
        }
      }
    }
  }
}
