/*  ----------------------------------------------------------------------------
   	/global
   	---------------------------------------------------------------------------- */

* {
	box-sizing: border-box;
}

html{
	font-size: 100%;
}

body {
	.bg-grey-light;
	.text-body;
	.text-lg;
	.font-body;
	.font-normal;
	.font-size-height(16,24);
	word-wrap: break-word;
	// hyphens: auto;
	.font-kerning;

}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::selection {
    background: #b3d4fc;
    text-shadow: none;
}



/*  Title
   	---------------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
	margin: 0;
	text-rendering: optimizeLegibility;
	.font-body;
	.font-normal;
}


h1, .h1 {
	padding-top: .125rem;
	padding-bottom: .25rem;
	.mb-5;
	.font-size-height(44, 48);
}

h2, .h2 {
	padding-top: 0;
	padding-bottom: 0;
	.mb-4;
	.font-size-height(33, 36);

}

h3, .h3 {
	padding-top: .0625rem;
	padding-bottom: .3125rem;
	.mb-3;
	.font-size-height(22,30);
}

h4, .h4 {
	padding-top: 0;
	padding-bottom: 0;
	.mb-3;
	.font-size-height(16, 24);
}

h5, .h5,
.small-caps {
	padding-top: .125rem;
	padding-bottom: .25rem;
	.mb-2;
	.uppercase;
	.font-semibold;
	.font-size-height(11,12);
}

h6, .h6 {
	padding-top: .3125rem;
	padding-bottom: .0625rem;
	.mb-1;
	.font-semibold;
	.font-size-height(11,18);
}

.small-uppercase{
	.uppercase;
	.font-semibold;
	.font-size-height(11,20);
}



/*  Inline Elements
	---------------------------------------------------------------------------- */

a {
	text-decoration: none;
	color: @color-link;
	transition: color 200ms linear;

	&:active,
	&:hover{ color: @color-link-hover; }
	&:focus {
		outline: 1px dotted fade(@color-body, 50%);
		outline-offset: .125rem;
	}
}

.content-block{
	a{
		border-bottom: 1px solid @color-link;
		color: @color-link;
		transition: color 200ms linear, border-bottom-color 200ms linear;

		&:active,
		&:hover{
			color: @color-link-hover;
			border-bottom-color: @color-link-hover;
		}
		&:visited {
			color: @color-link-visited;
			border-bottom-color: fade(@color-link-visited, 50%);
		}
	}
}

p {
	max-width: 60ch;
	margin: .75rem auto 1.125rem 0;

	// @media @xs-m-media {
	// 	padding-top: .0625rem;
	// 	padding-bottom: .5625rem;
    // }
	//
	// @media (min-width: @m-break) {
	// 	padding-top: .4375rem;
	// 	padding-bottom: .1875rem;
	// }

	&:last-child {
		margin-bottom: 0;
	}

	.text-center &{
		margin: .75rem auto 1.125rem;
	}
}

abbr[title] {
	text-decoration: none;
	.border-0;
	border-bottom-width: 2px;
	.border-solid;
	.border-grey-divider;
	.text-grey-60;
}

abbr[title]:hover {
	cursor: help;
}

em,
.em {
	font-style: italic;
}

small{
	line-height: .8;
}

mark,
.mark {
	// background-color: #ff0;
	.bg-yellow;
	.text-black;
}

hr,
.hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1.125rem 0;
    padding: 0;
}

del,
.del { text-decoration: line-through; }

ins,
.ins{
	text-decoration: none;
	// border-bottom: 1px dotted fade(@color-body, 50%);
	.border-0;
	.border-b;
	.border-dotted;
	.border-grey-60;
}

strong, b{
	.font-semibold;
	.text-black;
}

var{
	font-family: Georgia, serif;
	line-height: 1;
	.text-black;
}


/*  blockquote
   	---------------------------------------------------------------------------- */
/*	<blockquote>
  		<p>This is my blockquote;</p>
  		<cite>this is my attribution</cite>
	</blockquote>
*/

blockquote,
.blockquote {
	margin: 1.5rem auto;
	font-style: italic;

	cite {
	    .font-size-height(13,16);
	    font-style: normal;
	  }

}



/*	<figure class="quote">
		<blockquote>
        	It is the unofficial force—the Baker Street irregulars.<br>
        	What a bunch of maroons.
		</blockquote>
		<figcaption>Sherlock Holmes, <cite>Sign of Four</cite></figcaption>
	</figure>
*/
.quote {
	.my-6;

	blockquote{
		.m-0;
		font-style: normal;
	}

	p{
		padding-top: .0625rem;
		padding-bottom: .3125rem;
		.font-size-height(22,30);
	}

	blockquote + figcaption {
		display: block;
		.mt-1;
		.mb-3;
		padding: 0;
		text-align: left;
		font-size: inherit;
		.text-grey-60;

		cite,
		.cite{

			// &:before{
			// 	content: '— ';
			// }
		}
	}
}



/*  images
   	---------------------------------------------------------------------------- */

figure{
	margin: 0;
}

img {
    height: auto;
    width: auto;
    max-width: 100%;
}

.img-fluid { max-width: 100%; }

.img-left {
	display: block;
	@media (min-width: @m-break){			//960px
		float: left;
		margin-right: 1.875rem;
	}
}
.img-right {
	display: block;
	@media (min-width: @m-break){			//960px
		float: right;
		margin-left: 1.875rem;
	}
}
.img-center {
	margin: 0 auto 1.875rem;
	display: block;
}

.image-bg {
	background-size: cover;
	background-repeat: no-repeat;
	.bg-grey-divider;
	background-position: center center;
}



/*  Lists
   	---------------------------------------------------------------------------- */

ul,
ol{
	.pl-6;
	.my-3;

	ul,
	ol{
		padding-top: 0;
		padding-bottom: 0;
		.pt-1;
		.pb-1;
		margin: auto auto;
	}
}

ol {
	li {
		margin-bottom: 1em;
	}
}

.content-block{
	ul{
		list-style-type: none;

		& > li{
			position: relative;

			&:before{
				content: '—';
				position: absolute;
				left: -2.25rem;
			}
		}
	}
}


.nav,
.no-bullets,
.fields-list,
.validationErrors {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.list-inline {
	li {
		.inline-block;
		margin: 0;
		padding: 0;
	}
}

dl{
	.mt-1;
	dt{
		.font-semibold;
	}
	dd{
		.mb-3;
		.ml-0;

		//&:before{ content: '— '; }
	}
}



/*  Table
   	---------------------------------------------------------------------------- */
caption {
	padding: .3125rem 0 .375rem;
	.border-0;
	.border-b;
	.border-solid;
	.border-grey-divider;
	text-align: left;
	.font-semibold;
	.font-size-height(11,18);
}
table {
	vertical-align: top;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	margin: 1.125rem 0;
	text-align: left;
	.tabular-nums();
	.font-size-height(16,24);
}

td,
th {
	vertical-align: top;
	padding: .75rem .75rem 1.0625rem;
	// padding: 22px 10px 25px;
	.border-0;
	.border-b;
	.border-solid;
	.border-grey-divider;
	.text-body;

	&:first-child{
		padding-left: 0;
	}

	&:last-child{
		padding-right: 0;
	}
}
th{
	.font-semibold;
}

.table-wrap{
	position: relative;
	overflow: auto;
	width: 100%;
	max-width: 100%;

	table{
		min-width: 32rem;
	}
}




/*  Code
   	---------------------------------------------------------------------------- */

code,
kbd,
pre,
samp {
	padding: .375rem;
	background-color: #f1f1f1;
	word-wrap: break-word;
	font-family: @font-code;
	font-weight: @font-weight-code;
	.font-size-height(13, 16);
	white-space: pre-wrap;
	color: #272727;
}
pre {
	overflow: auto;
	padding: @field-padding;
	border: 1px solid #d7d7d7;
}



/*  address
   	---------------------------------------------------------------------------- */

address{
	padding-top: .4375rem;
	padding-bottom: .1875rem;
	font-style: normal;
}

















/*  ----------------------------------------------------------------------------
   	/icons
   	---------------------------------------------------------------------------- */

.glyph,
.icon {
	.inline-block;
	overflow: hidden;
	vertical-align: middle;
	height: 10px;
	width: 10px;
	text-align: left;
	text-indent: -5000px;
	.sprite;
}










/*  ----------------------------------------------------------------------------
   	/Helper classes
   	https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css
   	---------------------------------------------------------------------------- */

.no-scroll{ overflow: hidden; }

.no-margin { margin: 0; }
.no-padding { padding: 0; }
.no-float { float: none; }
.no-background { background: transparent; }
.no-border { border: 0; }

.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }
.text-justify { text-align: justify; }

.hidden {
    display: none !important;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.ir {
	display: block; border: 0;
	text-indent: -999em;
	overflow: hidden;
	background-color: transparent;
	background-repeat: no-repeat;
	text-align: left;
	direction: ltr;
	*line-height: 0;
}

.ir br { display: none; }

.drop-cap:first-letter {
	float: left;
	margin: 10px 10px 0 0;
	padding: 0 20px;
	font-size: 4em;
	font-family: inherit;
	line-height: 1;
	text-indent: 0;
	background: transparent;
	color: inherit;
}

/*Disable Element*/
 .disabled {
	pointer-events: none;
	opacity: .5;
 }

 .center-element {
	float: none;
	margin-left: auto;
	margin-right: auto;
 }

 .truncate {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
 }

// Center-align a block level element
 .center-block {
	 display: block;
	 margin-left: auto;
	 margin-right: auto;
 }








/*  ----------------------------------------------------------------------------
   	/Browser Upgrade
   	---------------------------------------------------------------------------- */

.browserupgrade {
	position: fixed;
	z-index: 999;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1.5em 2em;
	margin: 0;
	.bg-red;
	text-align: center;
	color: #fff;
}









/*  ----------------------------------------------------------------------------
   	/Fixing bug
   	---------------------------------------------------------------------------- */

#map_canvas img { /* Fixes broken Google map controls */
	max-width: none;
}

.dummy-element { /* For smooth transforms */
	transform: scale(.8);
	backface-visibility: hidden; /* Removes Flash of white and other jankiness */
	transition: all .4s cubic-bezier(0.680, -0.550, 0.265, 1.550);
}












/*  ----------------------------------------------------------------------------
   	/design stuff
   	----------------------------------------------------------------------------- */

//._grid{
//	position: absolute;
//	z-index: 2000;
//	top: 0;
//	left: 0;
//	bottom: 0;
//	display: none;
//	width: 100%;
//	max-width: 100%;
//	background: transparent url('/images/_grid.png') repeat left 0;
//	pointer-events: none;
//
//	@media (min-width: @s-break) {
//    	padding-right: .625rem;
//    }
//
//	&.active{
//		display: block;
//	}
//
//	.grid{
//		// height: 100vh;
//		// width: 1600px;
//		height: 100%;
//	}
//
//	.row{
//		flex-grow: 2;
//	}
//}
