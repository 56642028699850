
/*  ----------------------------------------------------------------------------
   	/generic
   	---------------------------------------------------------------------------- */

.page--portal-home .app-nav__item--dashboard .app-header__button,
.page--portal-result .app-nav__item--results .app-header__button,
.page--portal-orders-list .app-nav__item--orders .app-header__button,
.page--portal-settings .app-nav__item--account .app-header__button,
.section--blend .app-nav__item--blends .app-header__button,
.section--blends .app-nav__item--blends .app-header__button
{

    svg,
    .h {
        opacity: 1;
    }

    &:after{ content: ''; }
}

.tile {
    display: inline-flex;
	margin: .2em;
}











/*  ----------------------------------------------------------------------------
   	/Pottle
    ---------------------------------------------------------------------------- */


.field-collection{
    &__remove{
        .absolute;
        top: @s-6;
        right: @s-2;
    }
}


.jar-block{
    .relative;

    &__icon{
        .icon__jar{
            display: none;
        }
    }

    &__label{
        .mb-0;
    }

    .jar-block__input{
        border: 1px solid @color-border;
		.rounded;
        .bg-white;
        .font-size-height(15,24);
        padding: 0.4375rem 0.75rem;
    }
}






.status-tag {
    .rounded;
    .text-base;
    .leading-base;
    .font-semibold;
    .font-smoothing;
    display: inline-block;
    padding: .125rem .375rem;
    .bg-orange;
    color: #FFF;

    &.status-tag_red{ .bg-red; }
    &.status-tag_green{ .bg-green; }
    &.status-tag_blue{ .bg-blue; }

    &.draft {
        .text-orange;
        background: fade(@color-orange, 20%);
    }

    &.check,
    &.unacceptable,
    &.invalidumf,
    &.broken,
    &.contaminated,
    &.insufficient,
    &.leaked,
    &.failed,
    &.infeasible,
    &.unbounded,
    &.undefined,
    &.other {
      .bg-red;
    }

    &.received,
    &.testing,
    &.acceptable,
    &.running,
    &.pending,
    &.inprogress {
      .bg-blue;
    }

    &.reported,
    &.complete,
    &.partial-results,
    &.demo,
    &.optimal,
    &.final {
      .bg-green;
    }

    &.hold,
    &.invoiced {
      .bg-grey-40;
    }

    &.cancelled {
      .text-grey;
      background: fade(@color-grey, 20%);
    }
}


.status-label {
    .text-md;
    .leading-base;
    .font-semibold;
    .font-smoothing;
    .flex;
    .items-center;
    .text-orange;

    &:after {
        content: "";
        .w-2;
        .h-2;
        .ml-1;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &.waiting {
        &:after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTZBOCA4IDAgMTA4IDBhOCA4IDAgMDAwIDE2ek05IDRhMSAxIDAgMDAtMiAwdjMuODgyTDQuNTUzIDkuMTA2YTEgMSAwIDEwLjg5NCAxLjc4OGwzLTEuNUExIDEgMCAwMDkgOC41VjR6IiBmaWxsPSIjRjE4ODIwIi8+PC9zdmc+');
        }
    }

    &.check,
    &.unacceptable,
    &.invalidumf,
    &.broken,
    &.contaminated,
    &.insufficient,
    &.leaked,
    &.other {
        .text-red;

        &:after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTZBOCA4IDAgMTA4IDBhOCA4IDAgMDAwIDE2em0wLTYuMjVhMS4yNSAxLjI1IDAgMTAwIDIuNSAxLjI1IDEuMjUgMCAwMDAtMi41ek05IDR2NUg3VjRoMnoiIGZpbGw9IiNFMjFEMUQiPjwvcGF0aD48L3N2Zz4=');
        }
    }

    &.received,
    &.acceptable {
        .text-blue;

        &:after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMGE4IDggMCAxMDAgMTZBOCA4IDAgMDA4IDB6TTcgMTEuNDE0bDUuNzA3LTUuNzA3LTEuNDE0LTEuNDE0TDcgOC41ODYgNC43MDcgNi4yOTMgMy4yOTMgNy43MDcgNyAxMS40MTR6IiBmaWxsPSIjMDA3Q0JGIi8+PC9zdmc+');
        }
    }

    &.testing,
    &.inprogress {
        .text-blue;

        &:after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMTZBOCA4IDAgMTA4IDBhOCA4IDAgMDAwIDE2ek05IDRhMSAxIDAgMDAtMiAwdjMuODgyTDQuNTUzIDkuMTA2YTEgMSAwIDEwLjg5NCAxLjc4OGwzLTEuNUExIDEgMCAwMDkgOC41VjR6IiBmaWxsPSIjMDA3Q0JGIi8+PC9zdmc+');
        }
    }

    &.reported,
    &.complete,
    &.partial-results,
    &.final {
        .text-green;

        &:after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMGE4IDggMCAxMDAgMTZBOCA4IDAgMDA4IDB6TTcgMTEuNDE0bDUuNzA3LTUuNzA3LTEuNDE0LTEuNDE0TDcgOC41ODYgNC43MDcgNi4yOTMgMy4yOTMgNy43MDcgNyAxMS40MTR6IiBmaWxsPSIjMEU5RDQ3Ii8+PC9zdmc+');
        }
    }

    &.hold,
    &.invoiced {
        .text-grey-40;

        &:after {
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTggMGE4IDggMCAxMDAgMTZBOCA4IDAgMDA4IDB6TTcgMTEuNDE0bDUuNzA3LTUuNzA3LTEuNDE0LTEuNDE0TDcgOC41ODYgNC43MDcgNi4yOTMgMy4yOTMgNy43MDcgNyAxMS40MTR6IiBmaWxsPSIjYmNiZGJkIi8+PC9zdmc+');
        }
    }

    &.cancelled {
      .text-grey;
    }

    &.draft,
    &.submitted {
      display: none;
    }
}



.result-table{

    .m-0;

    &__item{
        padding-bottom: .375rem;
        .m-0;
    }

    td {
        border: 0;
    }

    &__def{
        font-style: normal;
        padding: .1875rem 0;
        margin: 0;
        width: 20%;

        &:before{ content: none; }
    }

    &__value{
        padding: .0625rem 1.5rem .3125rem;
        .font-semibold;
    }

    &__unit {
        opacity: .4;
        .font-normal;
    }

    &__chart{
        padding: .4375rem 0 0 0;
    }

    &__method {
        padding: .1875rem 0;
        width: 50%;
        @media (min-width: @l-break) {
            width: 60%;
        }
    }
}



.result-chart{
    height: .75rem;
    .text-body;

    &__bar{
        fill: currentColor;
        opacity: .2;
    }
}


.panel-filters{

    &__overlay{
        background-color: fade(@color-black, 25%);
    }

    &__inner{
        width: 100%;
        max-width: 37.5rem;
    }

    &__btn-close{
        top: calc(@s-17 - 0.25rem);
        left: @s-4;
    }

    &__content{
        overflow: auto;
        height: 100%;
    }

    &__content-inner{
        margin: @s-16 0 @s-10 @s-11;
        width: 25rem;
    }
}

@keyframes panelOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes panelSlideIn {
    from {
        transform: translateX(101%);
    }
    to {
        transform: none;
    }
}















/*  ----------------------------------------------------------------------------
   	/Dashboard
       ---------------------------------------------------------------------------- */

.page--portal-home {
    .app-header__logo {
        .text-yellow;
        background: fade(@color-body, 50%);
    }
    .topbar {
        background: #EFC00E;
        color: @color-black;
    }
}

.dashboard-user {
    .text-black;
}

.dashboard-blocks{

    &__filter {
        .inline-block;

        select {
            background: 0;
            .pr-5;
            .border-grey-divider;
            .font-semibold;
            .text-md;
            transition: .1s;
            &:hover {
                .border-grey-40;
            }
        }
    }

    &__list{
        .flex;
        flex-wrap: wrap;
        .-m-half;
    }

    &__item{
        .p-half;
    }
}




.dashboard-card{
    .flex;
    flex-direction: column;
    width: 17.5rem;
    height: 17.5rem;
    .p-2;
    .pt-5;
    .bg-white;
    color: inherit;

    &__link {
        display: flex;
        flex-direction: column;
        color: @color-body;
        &:hover,
        &:focus {
            color: @color-link-hover;
        }
    }

    &__num{
        .font-semibold;
        .font-size-height(55,58);
    }

    &__title{
        padding: .125rem 0 .25rem;
        .font-semibold;
        .font-size-height(15,16);

        &:after{
            content: '—';
            display: block;
        }
    }

    &__icon {
        .w-4;
        .h-4;
        .mt-3;
        .flex;
        .items-end;
        svg {
            color: @color-body;
        }
    }

    &__attn {
        margin-top: auto;
        .text-md;
        .font-semibold;

        svg {
            .mr-1;
        }
    }

    &.dashboard-card--orange{
        .dashboard-card__attn { color: @color-orange; }
    }
    &.dashboard-card--red{
        .dashboard-card__attn { color: @color-red; }
    }
}



.dashboard-updates {
    display: flex;
    flex-direction: column;
    width: 17.5rem;
    min-height: 17.5rem;
    padding: 0.9375rem 0.75rem 0.75rem;
    color: #fff;
    .bg-green;
    .font-semibold;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 1rem;
        padding: 0 0.25rem;
    }
    &__heading {
        display: flex;
        align-items: center;
        svg {
            margin-right: 0.5rem;
        }
    }
    &__date {
        .text-base;
    }
    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.25rem 0.75rem 0.75rem;
        background: fade(@color-grey, 20%);
        .text-base;
    }
    &__text {
        padding-bottom: 1rem;
        line-height: 1.45;
        ul {
            .no-bullets;
        }
        ul li {
            position: relative;
            padding-left: 0.5rem;
            &:before {
                content: "-";
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        ol {
            padding-left: 1em;
        }
    }
    &__cta {
        .text-base;
        .px-2;
        margin-top: auto;
        background: transparent;
        color: currentColor;
        text-transform: uppercase;
        border-color: fade(#fff, 20%);
        &:hover,
        &:focus {
            background: transparent;
            color: currentColor;
            border-color: currentColor;
        }
    }

}


// Latest Updates modal
.terms-modal,
.updates-modal,
.tour-modal {
    opacity: 0;
    pointer-events: none;
    transition: opacity .15s;
    &.is-open {
        opacity: 1;
        pointer-events: auto;
    }
}

.terms-modal {
    .modal__content {
        max-width: 62.5rem;
    }
}








/*  ----------------------------------------------------------------------------
   	/Orders
   	---------------------------------------------------------------------------- */

.order-list{
    padding: @s-3 @s-3 @s-20;

    @media (min-width: 64em) {
        padding: @s-3 @s-3 @s-20 @s-9;
    }

    &__header {
        .bg-white;
        .h-7;
        .-mb-px;
        border-bottom: 1px solid @color-grey-light;
        position: sticky;
        top: @s-9;
        z-index: 2;


        > div,
        > button {
            opacity: 0.5;
            height: 100%;
            .flex;
            .items-center;
            .font-semibold;
            .font-size-height(11,12);
        }
    }
    &__header-icon {
        .flex;
        .justify-center;
        .items-center;
        &:after {
            content: "";
            .bg-body;
            .w-2;
            .h-2;
            border-radius: 50%;
        }
    }
    &__header-cost {
        text-align: right;
    }
}

.order-header-grid {
    display: grid;
    grid-template-columns:  @s-7
                            @s-22
                            repeat(5, 1fr)
                            @s-7;

    @media (min-width: 64em) { //1024
        grid-template-columns:  @s-7
                                minmax(@s-22, 32rem)
                                @s-15
                                @s-8
                                minmax(@s-8, 1fr)
                                @s-15
                                @s-10
                                @s-7;
    }
}

.order-item{
    background-color: #FFF;
    margin-bottom: 1px;

    &__icon{
        padding: 1rem 1.125rem 1rem;
        .w-7;
        .h-7;
    }

    &__heading{
        margin-top: 1.25rem;
        margin-bottom: 1rem;
        padding-right: 1rem;
        .font-size-height(16,18);
    }

    &__reorder {
        .relative;
        .font-semibold;
        .text-base;
        .flex;
        .items-center;
        .justify-center;
        .bg-blue;
        .text-white;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 50%;
        cursor: default;
        .tooltip {
            margin-top: 0;
            top: 50%;
            left: 2rem;
            transform: translateY(-50%);
        }
        &:hover {
            .tooltip {
                opacity: 1;
            }
        }
    }

    &__date{
        margin-top: 1.25rem;
        padding: .125rem .75rem .25rem 0;
        flex-grow: 2;
        .font-semibold;
        .font-size-height(11,12);
    }

    &__stat {
        margin-top: 1.25rem;
    }

    &__stat-num{
        .pl-half;
        .font-semibold;
        .font-size-height(15,12);
    }

    &__status-tag {
        margin-top: 1.25rem;
    }

    &__status-label {
        margin-top: 1rem;
    }

    &__cost {
        text-align: right;
    }

    &__toggle{
        padding: 1.125rem 1.125rem 1.0625rem;
        .w-7;
        .h-7;

        & .icon__arrow-down{
            opacity: .4;
            transition: opacity 240ms linear;
        }

        &[aria-expanded="false"]:hover .icon__arrow-down{
            opacity: 1;
        }

        &[aria-expanded="true"] .icon__arrow-down{
            opacity: 1;
            transform: rotate(180deg);
        }
    }

    &__drawer{
        margin-top: 1px;
        .mb-2;
        padding: 1rem 1rem 2.5rem 2.5rem;
        animation: 200ms fadeIn 50ms both;
    }



    &__samples-details{
        margin-top: -1px;
        .border-0;
        .border-t;
        .border-solid;
        .border-grey-divider;
    }

    &__sidebar {
        width: 15rem;
    }

    &__info{
        margin-top: -1px;
        .pt-3;
        .pb-4;
        border-top-width: 4px;
        .border-solid;
        .border-grey-10;
    }

    &__info-col{
        .pr-1;

        &:not(:first-child) {
            .pt-2;
            .mt-2;
            .border-t;
            .border-solid;
            .border-grey-10;
        }
    }

    &__info-title{
        .mb-1;
        .font-semibold;
    }

    &__samples-list{
        .order-apply__pottles-item {
            .bg-grey-10;
        }
        .pottles-drop-zone {
            padding: 0;
            background: 0;
            min-height: 0;
        }
        .order-apply__pottles-item .test-item {
            .bg-white;
        }
    }

    &__cost {
        .pt-2;
    }

    &__cost-title{
        .flex-grow;
    }

    &__price{
        display: block;
        .font-size-height(32,24);
    }

    &__gst{
        opacity: .4;
    }

    &__footer {
        .button {
            .w-full;
            .-mt-px;
        }
    }

    &__footer-button--delete {
        svg {
            .-m-1;
            .mr-1;
        }
    }

    .order-apply__pottles-item{

    }

    .test-item{
        background-color: fade(@color-stone, 75%);
        box-shadow: none;
    }
}















/*  ----------------------------------------------------------------------------
   	/Order sample - step 1
   	---------------------------------------------------------------------------- */

.order-samples{
    // .flex;
}

.samples-sidebar{
    position: fixed;
    top: calc(@toolbar-height-desktop + @topbar-height);
    left: @header-width-desktop;
    bottom: 0;
}

.order-info-fields {

    .bg-grey-10;
    .p-2;
    .mb-px;

    .order-info__field {
        .mt-1;
    }

    .order-info__label {
        .pb-0;
    }

    .order-info__input {
        .bg-white;
        .border-white;
        padding: 0.4375rem 0.75rem;
    }
}

.add-sample-block{
    .mt-px;
    .p-2;
    background-color: fade(@color-body, 10%);

    &__title{
        .mb-1;
    }

    &__block{
        .flex;

    }

    &__add{
        .mt-0;
        .ml-1;
    }
}

.samples-list{
    flex-grow: 2;
    margin-left: 21rem;

    .field-batch{
        margin-top: 1px;
    }
}

.samples-list-empty {
    border: 1px dashed @color-body;
    .flex;
    .justify-center;
    .items-center;
    .p-10;
    .opacity-50;
}
















/*  ----------------------------------------------------------------------------
   	/Order summary
   	---------------------------------------------------------------------------- */

.order-summary{

}


.test-summary{
    flex-grow: 2;
    margin-right: 18.75rem;
}


.detail-summary{
    position: fixed;
    top: @toolbar-height-desktop;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 18.75rem;

    &__list{

    }

    &__item{
        border-top: @s-px solid fade(@color-body, 20%);
        .-mt-px;

        &:first-child{
            .mt-0;
            .pt-half;
            border-top-width: @s-half;
        }
    }

    &__btn{
        width: 100%;
    }

    &__heading{
        .flex-grow;
        .font-semibold;
        .text-left;
        .mb-0;
    }

    &__content{
        padding-top: .3125rem;
        padding-bottom: .0625rem;
        .font-semibold;
        .font-size-height(11,18);
    }

    &__total-title{

    }

    &__price{
        .font-normal;
    }

    &__gst{
        opacity: .4;
    }
}



.btn-comment{
    width: 100%;
    .p-3;
    .mb-3;
    background-color: fade(@color-body, 10%);

    &__icon{
        margin: -@s-half @s-1 -@s-half -2px;
        .p-1;
        .bg-grey;
        .rounded-full;
        color: @color-white;
    }

    &__text{
        .-mt-half;
        .-mb-half;
        padding-top: .125rem;
        padding-bottom: .25rem;
    }
}


.comment-block{
    width: 100%;
    .p-3;
    .mb-3;
    background-color: fade(@color-body, 10%);
    .text-left;

    &__copy{

    }

    &__btn{
        .flex;
        align-items: flex-start;
        .pt-2;
        .pb-1;
        .-mb-px;
    }

    &__icon{
        .icon__edit{
            margin-left: -@s-1;
        }
    }

    &__btn-txt{
        border-bottom: 1px solid @color-body;
        .font-size-height(11,12);
    }
}



.batch-pottle{
    .pottles-drop-zone {
        background: 0;
        padding: 0;
    }

    .test-item{
        box-shadow: none;
        background-color: fade(@color-stone, 75%);
    }
}













/*  ----------------------------------------------------------------------------
   	/ Dashboard - home
   	---------------------------------------------------------------------------- */

.banner-option{
    .bg-yellow;
    .pl-13;
    .pr-5;
}

.order-option{
    &__list{
        .flex;
    }

    &__item{
        flex-basis: 50%;
        max-width: 50%;
    }

    &__link{
        .relative;
        .block;
        .px-3;
        .py-5;
        .bg-yellow;
        .text-black;

        &:before{
            content: '';
            .absolute;
            top: 0;
            left: -@s-px;
            bottom: 0;
            width: @s-px;
            background-color: fade(@color-black, 10%);
        }

        .icon__arrow-right{
            .-ml-1;
        }

        &.disabled{
            cursor: not-allowed;
            pointer-events: none;
            opacity: .2;
        }
    }

    &__icon{
        .block;
        .pt-1;
    }

    &__title{
        .block;
        .mt-2;
        .mb-1;
    }
}

.portal-stats{
    &__item{

    }
}



/*  Dashboard feed
    ---------------------------------------------------------------------------- */

.dashboard-feed{
    border-top: 1px solid;
    .border-grey-divider;

    &__heading {
        .text-black;
        .pl-2;
    }

    &__list{
        display: flex;
        flex-wrap: wrap;
        .-my-1;
    }

    &__item{
        flex-basis: 16.875rem;
        max-width: 16.875rem;
        .p-2;
        .mb-6;
    }

    &__link{
        display: block;
        color: inherit;

        .icon__arrow-right{
            .mt-3;
            .-ml-1;
        }
        &:hover,
        &:focus {
            .dashboard-feed__icon,
            .dashboard-feed__title {
                color: @color-link-hover;
            }
        }
    }

    &__icon{
        display: block;
        .text-black;
    }

    &__title{
        .mt-2;
        .mb-0;
        .font-semibold;
        .text-black;
        .text-md;
    }

    &__content{
        p{
            .mt-1;
            padding-top: .3125rem;
            padding-bottom: .0625rem;
            .font-semibold;
            .font-size-height(11,18);
        }
    }


}



















/*  ----------------------------------------------------------------------------
   	/Order confirm
   	---------------------------------------------------------------------------- */

.order-confirm{
    align-items: center;
    height: calc(100vh - calc(@toolbar-height-desktop + @topbar-height));
    background-color: fade(@color-grey-light, 90%);

    &__icon{
        svg{ margin: 0 auto; }
    }

    &__links-list{
        .-mx-half;
    }

    &__link-item{
        .mx-half;
    }
}


.step-cards {
    gap: 0.5rem;
}

.step-card {
    display: flex;
    flex-direction: column;
    width: 17.5rem;
    height: 100%;
    padding: 1rem;
    background: @color-white;
    
    .h {
        .font-semibold;
        font-size: 1rem;
        margin: 0;
    }
    
    .desc {
        font-size: 0.6875rem;
        line-height: 1.45;
        p:first-child {
            .mt-1;
        }
        a {
            .font-semibold;
            color: currentColor;
            &:is(:hover, :focus) {
                text-decoration: underline 1px;
                text-underline-offset: 0.4em;
            }
        }
    }
    
    .cta {
        margin-top: auto;
    }
    
    .arrow {
        margin-left: -0.25rem;
    }
}










/*  ----------------------------------------------------------------------------
   	/ Results
    ---------------------------------------------------------------------------- */

.page--portal-result{

}



/*  result filters
    ---------------------------------------------------------------------------- */

.result-filter{
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    min-height: @s-9;

    &__list{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        .p-1;
        .pr-2;
    }

    &__item{
        padding: @s-half;
    }
}



/*  result list
    ---------------------------------------------------------------------------- */

.result-list{
    padding: @s-1 @s-3 @s-20 @s-9;

    &__list{
        .-ml-2;
    }

    &__header{
        .flex;
        .p-2;
        .pr-0;
        .mb-px;
        .bg-white;
        box-shadow: inset 0 -1px 0 0 @color-grey-light;

        &.all-selected {
            background: tint(@color-blue, 85%);
            box-shadow: inset 0 1px 0 0 #8fc6e5, inset -1px 0 0 #8fc6e5, inset 0 -1px 0 0 #8fc6e5, inset 1px 0 0 #8fc6e5;
        }
        .pottle-head__main {
            margin-left: -4px;
        }
    }

    &__check-area{
        .pt-3;
        .pr-3;
    }

    &__test-list {
        .bg-white;
        margin-top: -1px;
    }
}



/*  result sort
---------------------------------------------------------------------------- */
.result-sort {
    min-width: 10rem;

    > svg {
        position: absolute;
        top: 0.55rem;
        left: 0.5rem;
    }
    .select:before {
        content: "";
        .bg-grey-divider;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: @s-4;
    }
    .select:after {
        right: 0.6rem;
        opacity: 1;
        width: 0.75rem;
        height: 0.75rem;
        margin-top: -0.375rem;
    }
    select {
        .relative;
        .font-semibold;
        .text-md;
        .leading-lg;
        .border-grey-divider;
        .py-1;
        .pl-5;
        .pr-8;
        background: 0;
        transition: .1s;
        &:hover,
        &:focus {
            .border-grey-40;
        }
    }
}


/*  result test item
    ---------------------------------------------------------------------------- */

.result-test-item{
    display: block;
    // margin-bottom: 1px;
    box-shadow: inset 0 -1px 0 0 @color-grey-light;

    + .result-test-item {
        margin-top: -1px;
    }

    &__header {

        .is-selected & {
            background: tint(@color-blue, 85%);
            box-shadow: inset 0 1px 0 0 #8fc6e5, inset -1px 0 0 #8fc6e5, inset 0 -1px 0 0 #8fc6e5, inset 1px 0 0 #8fc6e5;
        }
    }

    &__checkbox {
        position: relative;
    }

    &__checkbox input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        &:checked {
            + label {
                .bg-blue;
                .border-blue;
                .text-white;
                svg {
                    opacity: 1;
                }
            }
        }
    }

    &__label {
        padding: 0;
        margin: 1.25rem 1rem;
        .w-2;
        .h-2;
        border: 2px solid fade(@color-body, 30%);
        transition: .15s;
        .rounded;
        .flex;
        .justify-center;
        .items-center;
        svg {
            opacity: 0;
        }
    }

    &__link{
        padding: 1.25rem .75rem .6875rem 0;
        flex-grow: 2;
        color: inherit;
    }

    &__test-name{
        .text-md;
        .font-semibold;
        flex-grow: 2;
        .px-2;
        margin-top: -.1875rem;
    }

    &__toggle{
        padding: 1.125rem 1.125rem 1.0625rem 1.125rem;

        & .icon__arrow-down{
            opacity: .4;
            transition: opacity 240ms linear;
        }

        &[aria-expanded="false"]:hover .icon__arrow-down{
            opacity: 1;
        }

        &[aria-expanded="true"] .icon__arrow-down{
            opacity: 1;
            transform: rotate(180deg);
        }
    }

    &__date{
        .text-md;
        .pl-1;
        flex-basis: 8rem;
        max-width: 8rem;
        text-align: right;
    }

    &__drawer{
        padding: .75rem 1.125rem 2.25rem 4.875rem;
        animation: 200ms fadeIn 50ms both;
    }
}


/*  result export
---------------------------------------------------------------------------- */
.result-export {
    position: relative;
    min-width: 10rem;

    &__button {
        .flex;
        .items-center;
        .relative;
        .font-semibold;
        .text-md;
        .leading-lg;
        .border;
        .border-grey-divider;
        .rounded;
        .py-1;
        .pl-1;
        .pr-6;
        background: 0;
        transition: .1s;
        &:hover,
        &:focus {
            .border-grey-40;
        }
        svg {
            .mr-1;
        }
        &:before {
            content: "";
            .bg-grey-divider;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: @s-4;
        }
        &:after {
            right: 0.6rem;
            opacity: 1;
            width: 0.75rem;
            height: 0.75rem;
            margin-top: -0.375rem;
        }
    }
}






/*  ----------------------------------------------------------------------------
   	/Contact
   	---------------------------------------------------------------------------- */













/*  ----------------------------------------------------------------------------
    /account pages - header
---------------------------------------------------------------------------- */
.site-header {
	position: relative;
	z-index: 100;
    height: 7.5rem;
	max-width: 100vw;
	background: @color-yellow;
	display: flex;
    align-items: center;
    .section-inner {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .mw-max {
        max-width: 90rem;
    }
}
.site-header__inner {
	width: 100%;
    height: 100%;
    padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.site-header__ui {
	width: 100%;
	height: 100%;
	display: flex;
}
.site-header__head {
	display: flex;
	align-items: center;
	position: relative;
    z-index: 10;
    width: 10rem;
}
.site-header__logo {
	display: block;
	padding: 2rem;
	margin-left: -2rem;
}
.site-header__body {
	width: 100vw;
    height: 100%;
	display: flex;
	align-items: center;
    justify-content: flex-end;
    width: calc(100% / 9 * 8);
    padding: 0 0 0 1rem;
}
.site-header__tools {
    display: flex;
    align-items: center;
}
.site-header__account {
	display: flex;
	align-items: center;
	justify-content: center;
	> * + * {
		margin-left: 1rem;
	}
	.button {
        white-space: nowrap;
        margin-top: 0;
        @media (max-width: (@s-break - 0.0625em)) {
            padding: 0.75rem;
        }
	}
}
.site-header__login {
	.button {
		border: 1px solid fade(@color-black, 10%);
		background: 0;
		color: @color-black;
		&:hover,
		&:focus {
			border-color: @color-black;
		}
	}
}
.site-header__signup {
	.button {
        color: #fff;
        background: @color-black;
        border: 1px solid transparent;
        &:hover,
        &:focus {
            color: @color-black;
            background: @color-yellow;
            border-color: @color-black;
        }
        &:focus {
            outline: 3px solid fade(black, 10%);
            outline-offset: 0;
        }
    }
}



/*  ----------------------------------------------------------------------------
    /account pages - footer
---------------------------------------------------------------------------- */
.site-footer {
    color: @color-black;
	background: @color-yellow;
    padding: 3.75rem 0 4.5rem;
    .section-inner {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .mw-max {
        max-width: 90rem;
    }
    a {
        color: @color-black;
    }
    .link {
        border-bottom: 1px solid;
        &:hover,
        &:focus {
		    background-color: fade(@color-stone, 30%);
        }
    }
    .site-footer__nav {
        display: none;
    }
}
.site-footer__body {
	text-align: center;
	@media (min-width: @l-break) {
		text-align: left;
		display: flex;
		flex-direction: column-reverse;
	}
}
.site-footer__main {
	@media (min-width: @l-break) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.site-footer__smalltext {
	margin-left: auto;
}

.site-footer__logos {
    margin: 2rem 0 0;
	display: flex;
	align-items: center;
    justify-content: center;
    @media (min-width: @l-break) {
		margin: 0 0 1rem;
		justify-content: flex-end;
	}
}
.site-footer__logo {
	& + & {
		margin-left: 1.75rem;
	}
}

.site-footer__nav {
	ul {
        @media (min-width: @l-break) {
			display: flex;
		}
	}
	li + li {
		margin-left: 1.5rem;
	}
	a {
		display: inline-block;
		.font-semibold;
		padding: 0.75rem 0 0.375rem;
		border-bottom: 1px solid transparent;
		&:hover,
		&:focus {
			border-bottom-color: currentColor;
		}
	}
}



/*  ----------------------------------------------------------------------------
    /sign up
    ---------------------------------------------------------------------------- */
#page--signup {
    .site-header__signup {
        display: none;
    }
}
.page--signup {
    .terms-area {
        height: auto;
    }
    .site-header__signup {
        display: none;
    }
    .site-footer__nav {
        display: block;
    }
}



/*  ----------------------------------------------------------------------------
    /login
    ---------------------------------------------------------------------------- */
#page--login {
    .site-header__login {
        display: none;
    }
}
.page--login {
    background: @color-yellow;
    .topbar {
        display: none;
    }
    .page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    main,
    .main-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .login-area {
        height: auto;
    }
}

.home-page-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    background-image: url('/images/login-bg.jpg@{timestamp}');
    .image-bg;
}

.login-area{
	position: relative;
	display: flex;
    justify-content: flex-start;
    align-items: center;
    height:100vh;
    background: @color-yellow;
	&.signup-area{
		height: auto;
	}
	&.signup-area--ok{
		height:100vh;
	}
}

.login{
	&__bg{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: @color-yellow;
		z-index: 0;
	}
}


.login-block{
	position: relative;
	width: 100%;
	max-width: 35rem;

	.signup-area &{
		max-width: 45rem;
	}

	.signup-area--ok &{
		max-width: 35rem;
	}

    .field + .field {
        .mt-2;
    }

	.field-phone{
		.field + .field{
			margin-top: 0;
		}
		.field-country{
			max-width: 10.75rem;
			flex-shrink: 0;
		}
		.field-tel{
			flex-grow: 1;
		}
	}

    @media (min-width: @s-break) {
        .fields-list{
            display: flex;
        }

        .field-half{
            flex-basis: 50%;
            max-width: 50%;
            margin-top: 0;
            padding-right: .5rem;
        }

        .field-half + .field-half{
            margin-top: 0;
            padding-left: .5rem;
            padding-right: 0;
        }
    }

	.field-password{
		width: 100%;
		padding: 0;
	}

	.field-checkbox{
		padding: 1rem 0;

		span{
			padding-left: .5rem;
		}

		a{
			position: relative;
            color: @color-body;
            .font-semibold;
			&:before{
				content: '';
				position: absolute;
				left:0;
				bottom: -.15rem;
				width: 100%;
				height: 1px;
				background-color: @color-body;
			}
		}
	}

    .field--text{
        position: relative;

        label{
            position: absolute;
            top: 0;
            left: 0;
            padding: 1.125rem;
            .m-0;
        }

        input{ .pl-7; }
    }

    .field--submit{
        order: 2;
    }



}
.login-block__error{
    .rounded;
    .font-semibold;
    .font-size-height(11,18);
    .text-white;
    .bg-orange;
    padding: 0.75rem 1rem;

    a{
        color: currentColor;
        border-bottom: 1px solid currentColor;
    }
    p {
        margin: 0;
    }
}

.login-block__back {
    width: 2.5rem;
    height: 2rem;
    margin-left: -3.25rem;
    .mr-2;
}
.login-block__title {
    img {
        @media (max-width: (@s-break - 0.0625em)) {
            display: none;
        }
    }
}
.login-block__form-list{
    display: flex;
    flex-direction: column;
}

.login-block__forgot{
    display: inline-block;
    vertical-align: top;
    padding-top: .125rem;
    padding-bottom: .1875rem;
    border-bottom: 1px solid @color-body;
    .font-semibold;
    .font-size-height(11,12);
    color: @color-body;

    transition: color 180ms linear, border-bottom-color 180ms linear;

    &:focus,
    &:hover{
        border-bottom-color: @color-orange;
        color: @color-orange;
    }
}

.login-forgot{
    width: 33.625rem;
}

.login-block__no-account {
    color: @color-khaki;
    .bg-stone;
    .font-semibold;
}








/*  ----------------------------------------------------------------------------
    /Terms
    ---------------------------------------------------------------------------- */
.page--terms {
    background: @color-yellow;
}
.terms-block {
    max-width: 90%;
    .terms-text {
        .p-5;
        @media (min-width: @l-break) {
            .p-10;
        }
    }
    @media (min-width: @m-break) {
        max-width: 45rem;
    }
}
.terms-text {
    .scrollbars;

    @media (min-width: @s-break) {
        max-height: calc(80vh - 10rem);
    }

    > ol,
    > ul {
        padding-left: 1.375rem;
    }
    ol,
    ul {
        font-size: 1.375rem;
        ol,
        ul {
            font-size: 1rem;
        }
    }
    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }
    ol>li {
        display: table;
        counter-increment: item;
        .mb-3;
    }
    ol>li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        .pr-1;
    }
    li ol>li {
        margin: 0;
    }
    li ol>li:before {
        content: counters(item, ".") " ";
    }
    li{
        .mb-1;
    }
    h3 {
        .mb-1;
    }
}
.terms-bar {
    .field-checkbox {
        margin: 0;
    }
    label {
        .font-normal;
    }
}



    /*  ----------------------------------------------------------------------------
    /Search results
    ---------------------------------------------------------------------------- */








/*  ----------------------------------------------------------------------------
   	/404
   	---------------------------------------------------------------------------- */
.error-page-content {
    height: 100vh;
    .p-7;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('/images/flying-bee.svg@{timestamp}') no-repeat 95% 90%;
    background-size: 25rem auto;
    @media (min-width: @xl-break) {
        background-size: 37.5rem auto;
    }
    .text {
        width: 40rem;
        max-width: 80%;
        background: @color-yellow;
        color: @color-black;
    }
    h1 {
        font-size: 8rem;
        line-height: 1.2;
        margin: 0;
    }
}



















/*  ----------------------------------------------------------------------------
   	/Components page
   	---------------------------------------------------------------------------- */
#page--components {
    hr {
        margin: 3.3125rem 0 3.3125rem;
    }
}
